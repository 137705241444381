<div class="col-sm-12 col-md-6 col-lg-4 offset-md-3 offset-lg-4">
    <!--START: SprintPay Logo -->
    <figure>
        <img
            class="d-block mx-auto my-0"
            alt="SprintPay logo powered by CargoSprint"
            src="./assets/images/sprintpay-horizontal-logo-powered.svg"
        />
    </figure>
    <!--END: SprintPay Logo -->
    <!-- Headers -->
    <div class="forgot-password__headers">
        <h6 class="text-center" *ngIf="isForgotPassword">Forgot your password?</h6>
        <h6 class="text-center" *ngIf="!isForgotPassword">Want to set your password?</h6>
        <p class="text-center fw-normal" *ngIf="isForgotPassword">
            Don't worry, just enter the email you registered with us.
        </p>
        <p class="text-center fw-normal" *ngIf="!isForgotPassword">
            Would you like to create a password for your account? Please confirm your email address to proceed.
        </p>
    </div>

    <!-- Forgot Password form -->
    <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()" class="row">
        <!-- Email input -->
        <div class="form-group">
            <mat-form-field class="d-block overflow-hidden pt-1" appearance="outline">
                <mat-label>Email</mat-label>
                <input
                    matInput
                    type="email"
                    id="email"
                    name="email"
                    data-cy="email"
                    formControlName="email"
                    placeholder="Enter your email"
                    [errorStateMatcher]="matcher"
                    [readonly]="isEditable"
                />
                <mat-error *ngIf="forgotPasswordForm.controls['email'].hasError('pattern')">
                    <i class="bi bi-exclamation-triangle-fill me-2"></i>Oops. The email address was malformed! Please
                    try again
                </mat-error>
                <mat-error *ngIf="forgotPasswordForm.controls['email'].hasError('required')">
                    <i class="bi bi-exclamation-triangle-fill me-2"></i>Required
                </mat-error>
            </mat-form-field>
        </div>

        <!-- Recover password button for forgot password-->
        <div class="col-sm-12 col-md-10 col-lg-10 offset-md-1 offset-lg-1" *ngIf="isForgotPassword">
            <button
                mat-button
                type="submit"
                class="d-block mx-auto border-0 rounded-0 w-100 text-uppercase btn__sprintpay btn__sprintpay--green"
                [disabled]="forgotPasswordForm.invalid || forgotPasswordForm.pristine"
            >
                Recover password
            </button>
        </div>

        <!-- Back button and Continue button for set-password -->
        <div class="row mx-1" *ngIf="!isForgotPassword">
            <div class="col-sm-6">
                <button
                    mat-button
                    class="d-block mx-auto border-0 rounded-0 w-100 text-uppercase btn__sprintpay btn__sprintpay--green"
                    (click)="goToHome()"
                >
                    Back
                </button>
            </div>
            <div class="col-sm-6">
                <button
                    mat-button
                    type="submit"
                    class="d-block mx-auto border-0 rounded-0 w-100 text-uppercase btn__sprintpay btn__sprintpay--green"
                >
                    Continue
                </button>
            </div>
        </div>
    </form>

    <!-- Link to other page -->
    <div class="text-center mb-2 mt-3 fw-normal" *ngIf="isForgotPassword">
        <span class="existing__account">Do you already have an account?</span>
        <span>&nbsp;&nbsp;</span>
        <a class="existing__account existing__account--link text-decoration-none" [routerLink]="['/login']">Sign up</a>
    </div>
</div>
<!--END: Forgot password -->
<ng-template #authenticationFlux>
    <app-authentication-flux
        messageAuthentication="This is an extra layer of security that helps protect your account by requiring a form of verification before granting access."
        [module]="moduleType"
    ></app-authentication-flux>
</ng-template>
