import { ComponentType } from '@angular/cdk/portal';
import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
    CartException,
    CartExceptionType,
    PaymentCartType,
    PaymentMetaData,
    PaymentMethods,
    PaymentMethodsType,
    PaymentRequest,
    PaymentResponse,
    PaymentToken,
    SaleStatus,
} from '@cargos/sprintpay-models';
import { PaymentService } from '@cargos/sprintpay-services';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, combineLatest, map, mergeMap, of, switchMap, take, takeUntil } from 'rxjs';
import { PersonalInformation } from 'src/app/models/customer/types';
import { AuthenticationFluxComponent } from 'src/app/modules/two-factor/authentication-flux/authentication-flux.component';
import { AuthenticationFluxService } from 'src/app/modules/two-factor/authentication-flux/authentication-flux.service';
import { CartBillService, InitialConfigService } from 'src/app/services';
import { CustomerFeaturesService } from 'src/app/services/features/features.service';
import { PaymentHandlerService } from 'src/app/services/payment/payment-handler.service';
import { RequestService } from 'src/app/services/requests.service';
import { PaymentMethodsSelected, TermsOfUseForm } from 'src/app/services/summary/models/types';
import { SummaryService } from 'src/app/services/summary/summary.service';
import { CustomerService } from 'src/app/services/utils/customer-handler.service';
import { ErrorHandlerService } from 'src/app/services/utils/error-handler.service';
import { PaymentFluxService } from 'src/app/services/utils/payment-flux.service';
import { TokenService } from 'src/app/services/utils/token.service';
import { UserSessionService } from 'src/app/services/utils/user-session.service';
import Swal from 'sweetalert2';
import { CartPayResponse } from '../../services/models/types';
import { PaymentConfirmationService } from '../../services/payment-confirmation.service';
import { PayButtonService } from './services/pay-button.service';

@Component({
    selector: 'app-pay-button',
    templateUrl: './pay-button.component.html',
    styleUrl: './pay-button.component.scss',
})
export class PayButtonComponent implements OnInit, OnDestroy {
    public isPaying: boolean = false;
    public isInvoiceCheckLimitValid: boolean = false;
    public isPaymentMethodSelectedValid: boolean = false;
    public isTermsOfUseValid: boolean = false;
    public paymentMethodsSelected: PaymentMethodsSelected | null = null;
    private isPaymentMethodDuplicated: boolean = false;
    private isGuest: boolean = false;
    private guestEmail: string | null = null;
    private unsubscribe$ = new Subject<void>();
    private termsOfUseForm: TermsOfUseForm | null = null;

    @ViewChild('authenticationFlux') authenticationFlux?: ComponentType<AuthenticationFluxComponent>;
    @Output() eventSubmitPayment = new EventEmitter<string>();

    constructor(
        private paymentHandlerService: PaymentHandlerService,
        private customerService: CustomerService,
        private matDialog: MatDialog,
        private authenticationFluxService: AuthenticationFluxService,
        private summaryService: SummaryService,
        private customerFeaturesService: CustomerFeaturesService,
        private ngxSpinnerService: NgxSpinnerService,
        private requestService: RequestService,
        private cartBillService: CartBillService,
        private tokenService: TokenService,
        private errorHandlerService: ErrorHandlerService,
        private userSessionService: UserSessionService,
        private payButtonService: PayButtonService,
        private paymentService: PaymentService,
        private paymentConfirmationService: PaymentConfirmationService,
        private initialConfigService: InitialConfigService,
        private paymentFluxService: PaymentFluxService
    ) {
        this.isGuest = this.customerService.isGuest();
    }

    ngOnInit(): void {
        this.subscribeToInvoiceCheckLimit();
        this.subscribeTermsOfUse();
        this.subscribePaymentMethodSelected();
        this.subscribeToUserAuthenticated();
        this.isPaymentMethodValid();
        this.getGuestCustomerInformation();
        this.onGuestVerificationCompleted();
        this.validatePaymentMethodDuplicated();

        this.authenticationFluxService.changeVerificationMethod
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((changed: boolean) => {
                if (changed) {
                    this.openFactorAuthentication(this.guestEmail || '');
                }
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    onGuestVerificationCompleted(): void {
        this.authenticationFluxService.accountCreated
            .pipe(
                mergeMap((verified: boolean) => {
                    if (verified) {
                        return this.payCart().pipe(take(1));
                    }

                    return of(null);
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe({
                next: (response) => {
                    this.isPaying = false;
                    this.ngxSpinnerService.hide();
                    if (response) {
                        this.onPaymentComplete(response);
                    }
                },
                error: (cartExceptions: any) => {
                    this.ngxSpinnerService.hide();
                    this.isPaying = false;
                    this.onPaymentError(cartExceptions);
                },
            });
    }

    validateAndPay(): void {
        if (this.isPaying) {
            return;
        }

        this.isPaying = true;
        this.eventSubmitPayment.emit();

        this.paymentHandlerService
            .isAllowedToMakeAPayment()
            .pipe(
                take(1),
                switchMap(() => this.pay())
            )
            .subscribe({
                next: (response) => {
                    this.isPaying = false;
                    this.ngxSpinnerService.hide();
                    if (this.isGuest) {
                        return;
                    }
                    this.onPaymentComplete(response);
                },
                error: (cartExceptions: any) => {
                    this.ngxSpinnerService.hide();
                    this.isPaying = false;
                    this.onPaymentError(cartExceptions);
                },
            });
    }

    onPaymentComplete(cartPayResponses: CartPayResponse[]): void {
        const successfulPayments = cartPayResponses.filter(
            (payResult) => payResult.saleStatus === SaleStatus.SALE_SUCCESS
        );

        if (successfulPayments.length) {
            this.paymentConfirmationService.setPaymentResponse(cartPayResponses);
        } else {
            const msg =
                cartPayResponses.length === 1
                    ? this.errorHandlerService.errorMsg({ errors: cartPayResponses[0].errorPayment?.error })
                    : '<p>We could not process the transaction(s) in your cart due to a technical issue on our end. Please try paying again. If the issue keeps happening, contact <a href= "mailto: support@cargosprint.com"> support@cargosprint.com </a></p>';

            Swal.fire({
                title: 'Unable to complete your transaction',
                html: msg,
                icon: 'error',
                customClass: {
                    htmlContainer: 'swal-container',
                },
                showCloseButton: true,
                allowOutsideClick: false,
                confirmButtonText: 'CLOSE',
            });
        }
    }

    onPaymentError(cartExceptions: any): void {
        if (cartExceptions?.isArray()) {
            const cartExceptionsArray = cartExceptions || [];
            cartExceptionsArray?.forEach((cartException: CartException) => {
                if (cartException.code === CartExceptionType.TERMS_OF_USE_INVALID) {
                    this.markTermsOfUseInvalid();
                }
            });
        }
        const template =
            cartExceptions && cartExceptions?.response && cartExceptions.response.data
                ? this.errorHandlerService.errorMsg(cartExceptions.response.data)
                : this.errorHandlerService.errorMsg(cartExceptions);
        Swal.fire({
            title: 'Oops...',
            html: template,
            icon: 'error',
            customClass: {
                htmlContainer: 'swal-container',
            },
            allowOutsideClick: false,
            confirmButtonText: 'Ok',
        });
    }

    // TODO: Refactot pay method, hurts code
    pay(): Observable<CartPayResponse[]> {
        this.payButtonService.setPayButtonSubmitted(true);
        if (this.isGuest) {
            this.openFactorAuthentication(this.guestEmail || '');
            return of([]);
        }

        return this.payCart();
    }

    payCart(): Observable<CartPayResponse[]> {
        this.ngxSpinnerService.show();
        return this.getPaymentsRequests$().pipe(
            take(1),
            switchMap((paymentRequests: PaymentRequest[]) => {
                return this.paymentService.pay(paymentRequests).pipe(
                    take(1),
                    switchMap((payResults: PaymentResponse[]) => {
                        const successfulPayments = payResults.filter(
                            (payResult: PaymentResponse) => payResult.saleStatus === SaleStatus.SALE_SUCCESS
                        );
                        const invoiceInCart = this.cartBillService.isThereInvoicesInCart();
                        if (successfulPayments.length) {
                            if (successfulPayments[0].authenticationToken) {
                                this.tokenService.setCurrentUser(successfulPayments[0].authenticationToken);
                            }

                            // ! Test this feature, to validate if the cart is updated afer pay - Nancy
                            return this.initialConfigService.getPaymentMethodSelectedAndGetCart$().pipe(
                                take(1),
                                map(() => this.getCartResponses(payResults, invoiceInCart))
                            );
                        }

                        return of(this.getCartResponses(payResults, invoiceInCart));
                    })
                );
            })
        );
    }

    private getCartResponses(payResults: PaymentResponse[], isThereInvoicesInCart: boolean): CartPayResponse[] {
        return <CartPayResponse[]>payResults.map((payResult: PaymentResponse) => ({
            saleStatus: payResult.saleStatus || SaleStatus.SALE_ERROR,
            paymentMethod: <PaymentMethodsType>payResult.paymentMethod || undefined,
            totalPayments: payResult.totalPayments,
            completedPayments: payResult.completedPayments || [],
            notificationEmail: payResult.notificationEmail,
            isThereInvoicesInCart,
            transactionId: payResult.transactionId || undefined,
            errorPayment: payResult.errorPayment,
        }));
    }

    // TODO: This logic is duplicated in the paypal button - low
    getPaymentsRequests$(): Observable<PaymentRequest[]> {
        if (this.isPaymentMethodDuplicated) {
            return this.getPaymentRequest().pipe(
                map((payments) => {
                    return [payments].filter((request): request is PaymentRequest => request !== null);
                })
            );
        }

        return combineLatest([this.getPaymentRequestBelowThreshold(), this.getPaymentRequestAboveThreshold()]).pipe(
            map(([paymentRequestBelowThreshold, paymentRequestAboveThreshold]) => {
                return <PaymentRequest[]>(
                    [paymentRequestBelowThreshold, paymentRequestAboveThreshold].filter((request) => request !== null)
                );
            })
        );
    }

    // TODO: Verify how customerReference is used un beta - medium
    private getPaymentRequestBelowThreshold(): Observable<PaymentRequest | null> {
        const payments = this.cartBillService.getCartPaymentRequests(false);

        return of(payments.length).pipe(
            switchMap((length) => {
                if (length) {
                    return this.summaryService.getPaymentTokenOfPaymentMethodSelected$(false, this.isGuest).pipe(
                        map((paymentToken: PaymentToken | null) => {
                            if (!paymentToken) {
                                return null;
                            }

                            const clientCart = this.requestService.updateRequestBeforePay(payments);

                            if (this.isGuest && paymentToken) {
                                return this.getGuestPaymentRequest(clientCart, paymentToken);
                            }

                            return {
                                clientCart,
                                paymentMethod: this.summaryService.instant_payment_method?.method,
                                paymentToken,
                                variableTerms: true,
                            } as PaymentRequest;
                        })
                    );
                }

                return of(null);
            })
        );
    }

    private getGuestPaymentRequest(clientCart: PaymentCartType[], paymentToken: PaymentToken): PaymentRequest | null {
        const customerReference = this.paymentFluxService.instant_customer_reference;
        const paymentNonce = this.summaryService.instant_guest_payment_information?.creditCard?.nonce;

        return {
            paymentMetaData: {
                ...this.buildPaymentMetadata(),
                customerReference,
            },
            braintreePaymentRequest: {
                ...this.buildPaymentMetadata(),
                customerReference,
                paymentNonce,
            },
            customerReference,
            paymentNonce,
            paymentToken,
            clientCart: this.buildClientCart(clientCart),
            paymentMethod: PaymentMethods.CREDIT_CARD,
        } as PaymentRequest;
    }

    private getPaymentRequest(): Observable<PaymentRequest | null> {
        const payments = this.cartBillService.getCartPaymentRequests();

        return this.summaryService.getPaymentTokenOfPaymentMethodSelected$(false).pipe(
            map((paymentToken: PaymentToken | null) => {
                if (!paymentToken) {
                    return null;
                }
                let paymentMethod = this.summaryService.instant_payment_method?.method;
                const clientCart = this.requestService.updateRequestBeforePay(payments);

                return {
                    clientCart,
                    paymentMethod,
                    paymentToken,
                } as PaymentRequest;
            })
        );
    }

    buildPaymentMetadata(): PaymentMetaData {
        const personalInformation = this.summaryService.instant_guest_personal_information;
        const addressInformation = this.summaryService.instant_guest_address_information;

        return PaymentMetaData.fromJson({
            ...personalInformation,
            countryCode: personalInformation?.countryCode,
            phoneNumber: personalInformation?.phone,
            city: addressInformation?.city,
            state: addressInformation?.state,
            postalCode: addressInformation?.zipCode,
            country: addressInformation?.country,
            street: addressInformation?.street,
            street2: addressInformation?.street2,
        });
    }

    private buildClientCart(cart: PaymentCartType[]): PaymentCartType[] {
        return cart.map((item) => {
            const clientObject = {
                ...item,
                cargoFacility: item.facility?.name || '',
                paidTo: item.facility?.paidTo || '',
            };

            if (this.customerService.getCustomer().isGuest) {
                delete clientObject.customer;
                delete clientObject.originalRequestor;
            }

            return clientObject;
        });
    }

    // TODO: Verify how customerReference is used un beta - medium
    private getPaymentRequestAboveThreshold(): Observable<PaymentRequest | null> {
        return this.cartBillService.isCartAboveThreshold$().pipe(
            switchMap((isCartAboveThreshold: boolean) => {
                if (!isCartAboveThreshold) {
                    return of(null);
                }
                const payments = this.cartBillService.getCartPaymentRequests(true);
                const clientCart = this.requestService.updateRequestBeforePay(payments);

                return this.summaryService.getPaymentTokenOfPaymentMethodSelected$(true).pipe(
                    map((paymentToken: PaymentToken | null) => {
                        let paymentMethod = this.summaryService.instant_payment_method_above_threshold?.method;

                        return {
                            clientCart,
                            paymentMethod,
                            paymentToken,
                            variableTerms: true,
                        } as PaymentRequest;
                    })
                );
            })
        );
    }

    /**
     * @method openFactorAuthentication()
     * @description verify if the profile components contains the payment methods section
     */
    openFactorAuthentication(email: string): void {
        this.matDialog.closeAll();
        setTimeout(() => {
            this.matDialog.open(this.authenticationFlux as ComponentType<unknown>, {
                id: 'authenticationFlux',
                disableClose: true,
                width: '55em',
                data: { email },
            });
        }, 200);
    }

    /**
     * @method getGuestCustomerInformation
     * @description Fetches guest customer information if the user is a guest.
     *              Subscribes to the guest customer information observable and updates the component state.
     */
    getGuestCustomerInformation(): void {
        if (this.isGuest) {
            this.summaryService
                .getGuestCustomerInformation$()
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe({
                    next: (information: PersonalInformation | null) => {
                        this.guestEmail = information?.email || null;
                    },
                });
        }
    }

    subscribeToInvoiceCheckLimit(): void {
        this.customerFeaturesService
            .isInvoiceCheckLimitValid$()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (isInvoiceCheckLimitValid) => {
                    this.isInvoiceCheckLimitValid = isInvoiceCheckLimitValid;
                },
            });
    }

    isPaymentMethodValid(): void {
        this.customerFeaturesService
            .isPaymentMethodValid$()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (isPaymentMethodSelectedValid) => {
                    this.isPaymentMethodSelectedValid = isPaymentMethodSelectedValid;
                },
            });
    }

    subscribeTermsOfUse(): void {
        this.summaryService
            .getTermsOfUseValid$()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (termsOfUse: TermsOfUseForm) => {
                    this.isTermsOfUseValid = termsOfUse.isValidForm;
                    this.termsOfUseForm = termsOfUse;
                },
            });
    }

    markTermsOfUseInvalid(): void {
        this.termsOfUseForm?.termsOfUseFormGroup?.get('termsOfUse')?.markAsTouched();
    }

    subscribePaymentMethodSelected(): void {
        this.summaryService
            .getPaymentMethodsSelectedName$()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (paymentMethodsSelected: PaymentMethodsSelected) => {
                    this.paymentMethodsSelected = paymentMethodsSelected;
                },
            });
    }

    subscribeToUserAuthenticated(): void {
        this.userSessionService
            .isAuthenticated$()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((isAuthenticated: boolean) => {
                this.isGuest = !isAuthenticated;
            });
    }

    validatePaymentMethodDuplicated(): void {
        this.summaryService
            .getPaymentMethodsSelected$()
            .pipe(
                map((paymentMethodsSelected) => {
                    return (
                        paymentMethodsSelected.paymentMethodSelected?.method ===
                            paymentMethodsSelected.paymentMethodSelectedAboveThreshold?.method &&
                        paymentMethodsSelected.paymentMethodSelected?.token ===
                            paymentMethodsSelected.paymentMethodSelectedAboveThreshold?.token
                    );
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe({
                next: (isPaymentMethodDuplicated) => {
                    this.isPaymentMethodDuplicated = isPaymentMethodDuplicated;
                },
            });
    }
}
