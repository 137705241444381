<div class="cart-awb">
    <div class="d-flex justify-content-between header" (click)="panelOpenState = !panelOpenState">
        <div class="position-relative mb-2">
            <div class="d-flex">
                <mat-icon class="icon" *ngIf="!panelOpenState">expand_more</mat-icon>
                <mat-icon class="icon" *ngIf="panelOpenState">expand_less</mat-icon>
                <div class="d-flex" *ngIf="facilityName">
                    <div class="mat-subtitle-1 name">{{facilityName}}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="info">
        <ng-container *ngIf="!panelOpenState">
            <div class="d-flex flex-column-reverse flex-md-row justify-content-between w-100">
                <div class="d-flex flex-column flex-grow-1">
                    <div class="mat-caption title">
                        MAWB/AWB/MBL:
                        <span class="information">{{paymentRequest.awb}}</span>
                    </div>
                    <div class="mat-caption title" *ngIf="hawb">
                        HAWB/HBL/Container:
                        <span class="information text-break"> {{hawb}} </span>
                    </div>
                    <div class="mat-caption title" *ngIf="paymentType">
                        Type: <span class="information text-break">{{paymentType}}</span>
                    </div>
                </div>
                <div class="d-flex mat-subtitle-1 amount flex-1">USD {{totalbyAwb | currency}}</div>
            </div>
        </ng-container>
        <div *ngIf="panelOpenState">
            <div class="d-flex flex-column flex-md-row amount-container">
                <div class="mat-body-2 payment py-2 d-flex flex-grow-1">PAYMENT INFORMATION</div>
                <div class="d-flex mat-subtitle-1 amount flex-1">USD {{totalbyAwb | currency}}</div>
            </div>
            <div *ngIf="paymentRequest" class="my-3">
                <app-cart-item-details
                    [rates]="rates"
                    [id]="paymentRequest.id"
                    [hasNonReservationFee]="hasNonReservationFee"
                    class="flex-column"
                ></app-cart-item-details>
            </div>
        </div>
        <div class="cart-awb-buttons">
            <app-cart-buttons [paymentCart]="paymentRequest"></app-cart-buttons>
        </div>
    </div>
</div>
