import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs';
import { Locations } from 'src/app/models/utils/locations.model';
import { FacilityService } from 'src/app/services/facility.service';
import { RestService } from 'src/app/services/rest.service';
import { Emotrans } from 'src/app/utils/companies/emotrans';
import { Domains, paymentTypesDSV } from 'src/app/utils/constants';
import { ErrorMatcher } from 'src/app/utils/error-matcher';
import { sortPaymentTypes } from 'src/app/utils/ordered-data';
import { StepperSteps } from 'src/app/utils/stepper-types';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { CustomerService } from '../../../../../../../services/utils/customer-handler.service';
import { PaymentFluxService } from '../../../../../../../services/utils/payment-flux.service';
import { PaymentDetailService } from '../../payment-detail.service';
import { StepperService } from '../../services/stepper.service';

@Component({
    selector: 'app-emotrans-reference',
    templateUrl: './emotrans-reference.component.html',
})
export class EmotransReferenceComponent implements OnInit {
    hasExternalLookup: boolean = false;
    emotransReferenceForm!: FormGroup;
    typeOptions!: string[];
    urlNextStep: string;
    onEdition: boolean;
    paymentMethodTypes: any[];
    locationAssigned: any;
    locations: Locations[];
    disabledInput: boolean = false;
    matcher: ErrorMatcher = new ErrorMatcher();
    private _customer: any;
    private readonly _approvalLevels: any;
    private readonly _currentPayment: any;
    private readonly _companyName: string;
    private readonly _paymentsURL: String;

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _paymentFluxService: PaymentFluxService,
        private _formBuilder: FormBuilder,
        private _customerService: CustomerService,
        private _router: Router,
        private _paymentDetailService: PaymentDetailService,
        private _stepperService: StepperService,
        private _restService: RestService,
        private _facilityService: FacilityService
    ) {
        this._currentPayment = this._paymentFluxService.getCurrentPayment();
        this.hasExternalLookup = !!this._currentPayment.facility?.externalLookup;
        if (!this._currentPayment) {
            this._router.navigate([`/admin/facilityPayments/newPayment`]);
        }
        this._paymentsURL = environment.uris.method.payments;
        this.onEdition = !!(this._currentPayment && this._currentPayment.redirectRoute);
        this._customer = this._customerService.getCustomer();
        this._companyName = this._customer?.approvalLevels?.company?.name || '';
        this.urlNextStep =
            this._currentPayment && this._currentPayment.redirectRoute
                ? this._currentPayment.redirectRoute
                : this._companyName
                  ? '../paymentDetails'
                  : '../customerReference';
        this._approvalLevels = this._customer.approvalLevels
            ? this._customer.approvalLevels.company.paymentReference.map((item: any) => {
                  if (item.name === 'Type') {
                      return item;
                  }
              })
            : '';
        this.paymentMethodTypes = [];
        const facility = this._currentPayment.facility;
        this._facilityService
            .getPaymentTypes(facility.id)
            .pipe(take(1))
            .subscribe({
                next: (paymentTypesResult) => {
                    if (paymentTypesResult.length) {
                        this.typeOptions = sortPaymentTypes(paymentTypesResult);
                    } else {
                        this.typeOptions = this._customerService.isCustomerEmailInDomain(Domains.DSV)
                            ? JSON.parse(JSON.stringify(paymentTypesDSV))
                            : JSON.parse(JSON.stringify(Emotrans.paymentTypes));
                    }
                    if (this._currentPayment?.emotransCustomerReference?.type) {
                        this.verifyIfPaymentTypeExists(this._currentPayment.emotransCustomerReference.type);
                    }
                },
            });
        this.locationAssigned =
            this._customer.approvalLevels && this._customer.approvalLevels.company
                ? this._customer.approvalLevels.company.firstLocation
                : null;

        this.locations = [];
    }

    ngOnInit(): void {
        this._setFromBuilder();
        if (this.locationAssigned) {
            this.emotransReferenceForm.get('locationId')!.setValue(this.locationAssigned.id);
            this.emotransReferenceForm.get('locationName')!.setValue(this.locationAssigned.name);
        } else {
            this.getLocations();
        }
        this._getPaymentAmount(this._approvalLevels);
        if (this._currentPayment && this._currentPayment.emotransCustomerReference) {
            this.emotransReferenceForm.patchValue(this._currentPayment.emotransCustomerReference);
            this.type ? this.type.setValidators([Validators.required]) : '';
        }
    }

    verifyIfPaymentTypeExists(currentPaymentType: string): void {
        if (!this.typeOptions.includes(currentPaymentType)) {
            this.typeOptions.push(currentPaymentType);
            this.disabledInput = true;
        }
    }

    /**
     * @method setFromBuilder()
     * @description Set the form requirements to be a valid submission
     */

    private _setFromBuilder(): void {
        this.emotransReferenceForm = this._formBuilder.group({
            paymentAmount: new FormControl<string | null>(null),
            type: new FormControl<string | null>(null),
            locationId: new FormControl<string | null>(null),
            locationName: new FormControl<string | null>(null),
        });
        this.emotransReferenceForm = this._paymentDetailService.validateCompanyForm(this.emotransReferenceForm);
    }

    /**
     * @method paymentAmount()
     * @description: Convenience getter for easy access to form fields
     */

    get paymentAmount(): FormGroup {
        return this.emotransReferenceForm.get('paymentAmount') as FormGroup;
    }

    /**
     * @method type()
     * @description: Convenience getter for easy access to form fields
     */

    get type(): FormGroup {
        return this.emotransReferenceForm.get('type') as FormGroup;
    }

    /**
     * @method locationId()
     * @description: Convenience getter for easy access to form fields
     */

    get locationId(): FormGroup {
        return this.emotransReferenceForm.get('locationId') as FormGroup;
    }

    /**
     * @method locationName()
     * @description: Convenience getter for easy access to form fields
     */

    get locationName(): FormGroup {
        return this.emotransReferenceForm.get('locationName') as FormGroup;
    }

    /**
     * @method setPaymentAmount()
     * @param (event: any)
     * @description Convenience setter for easy access to form fields
     */
    set setPaymentAmount(event: any) {
        this.paymentAmount!.setValue(event);
    }

    /**
     * @method setType()
     * @param (event: any)
     * @description Convenience setter for easy access to form fields
     */
    set setType(event: any) {
        this.type!.setValue(event);
    }

    /**
     * @method _getPaymentAmount()
     * @param (approvalLevels: any)
     * @description Convenience setter for easy access to form fields
     */

    private _getPaymentAmount(approvalLevels: any): void {
        this.paymentMethodTypes = approvalLevels[0].paymentReferenceLookups.map((item: any) => {
            return item.type;
        });
    }

    /**
     * @method trackBy()
     * @param (index: number)
     * @param (item: any)
     * @description Compare the current object with the new one; takes the index and the current item as arguments and returns the unique identifier by which that item should be tracked
     */

    trackBy(index: number, item: any): string {
        return item.label;
    }

    /**
     * @method getEmotransValues()
     * @description
     */

    getEmotransValues(): void {
        if (this.emotransReferenceForm.invalid) {
            this.emotransReferenceForm.markAllAsTouched();
        } else {
            if (
                this._currentPayment.id &&
                this._currentPayment.emotransCustomerReference.paymentAmount !==
                    this.emotransReferenceForm.get('paymentAmount')?.value
            ) {
                this._currentPayment.externalData
                    ? (this._currentPayment.externalData.type = this.emotransReferenceForm.get('paymentAmount')!.value)
                    : '';
                this._paymentFluxService.setData('emotransCustomerReference', this.emotransReferenceForm.getRawValue());
                Swal.fire({
                    title: 'Important !',
                    icon: 'info',
                    text: 'You have changed some key information! Please review your request before continuing.',
                    showConfirmButton: true,
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#14bb9c',
                    allowOutsideClick: false,
                }).then((): void => {
                    this._stepperService[StepperSteps.STEP_ZERO].next(true);
                    this._router.navigate(['/admin/facilityPayments/newPayment/flux/paymentDetails']);
                });
            } else {
                this._stepperService[StepperSteps.STEP_ZERO].next(true);
                if (this.onEdition) {
                    this._stepperService[StepperSteps.STEP_FIRST].next(true);
                    this._stepperService[StepperSteps.STEP_SECOND].next(true);
                }
                this._paymentFluxService.setData('emotransCustomerReference', this.emotransReferenceForm.getRawValue());
                this._router.navigate([this.urlNextStep], { relativeTo: this._activatedRoute });
            }
        }
    }

    /**
     * @method getLocations()
     * @description
     */

    getLocations(): void {
        const url: string = this._paymentsURL + '/customer/approval-flow/information';
        this._restService.get<{ locations: Locations[] }>(url, {}).then((result): void => {
            this.locations = result.locations;
        });
    }

    /**
     * @method getLocations()
     * @description
     */

    locationSelected(item: any, event: any): void {
        if (event.isUserInput) {
            this.emotransReferenceForm.get('locationName')!.setValue(item.name);
        }
    }
}
