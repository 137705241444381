import { Component } from '@angular/core';
import { TypeModuleMFA } from 'src/app/modules/two-factor/verification-code/models/two-factor-models';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
})
export class ForgotPasswordComponent {
    public typeModuleMFA: TypeModuleMFA = 'FORGOT_PASSWORD';

    constructor() {}
}
