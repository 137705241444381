@if(creditCard) {
<div class="d-flex flex-row flex-grow-1 flex-wrap">
    <mat-selection-list [multiple]="false" #guestCreditCard>
        <mat-list-option
            [value]="creditCard.nonce"
            (click)="selectPaymentMethod()"
            [selected]="paymentMethodSelected === PaymentMethodsEnum.CREDIT_CARD"
            checkboxPosition="before"
            color="primary"
        >
            <ng-container *ngTemplateOutlet="creditCardGuest"></ng-container>
        </mat-list-option>
    </mat-selection-list>
    <button
        mat-button
        type="submit"
        class="d-flex align-items-center mat-body-1 btn__sprintpay btn__sprintpay--link btn-green"
        (click)="editCard()"
    >
        EDIT
    </button>
</div>
} @else if (!creditCard){
<button mat-button type="submit" class="d-flex align-items-center mat-body-1 btn-green" (click)="editCard()">
    <mat-icon matIconPrefix>add</mat-icon>
    ADD NEW CREDIT CARD
</button>
}
<ng-template #creditCardGuest>
    @if (creditCard) {
    <div class="d-flex flex-row align-items-center pe-3">
        <app-card-type [cardType]="creditCard.details.cardType" width="2.3em" class="me-3"></app-card-type>
        @if (creditCard && creditCard.details.lastFour && creditCard.details.expirationMonth &&
        creditCard.details.expirationYear) {
        <div class="d-flex flex-grow-1 flex-column">
            <app-last-four-digits [lastFourDigits]="creditCard.details.lastFour"></app-last-four-digits>
            <app-expiration-date
                [expirationMonth]="creditCard.details.expirationMonth"
                [expirationYear]="creditCard.details.expirationYear"
            ></app-expiration-date>
        </div>
        }
    </div>
    }
</ng-template>
