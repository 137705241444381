import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { permissionsSection } from '../../../../utils/constants';

// COMPONENTS

import { PaymentMethodsComponent } from './payment-methods.component';

const routes: Routes = [
    {
        path: '',
        title: 'SprintPay | Payment methods',
        canLoad: [NgxPermissionsGuard],
        data: {
            breadcrumb: { alias: 'payment-methods' },
            permissions: {
                only: permissionsSection.paymentMethods,
                redirectTo: 'admin/home',
            },
        },
        children: [
            {
                path: '',
                redirectTo: '/admin/facilityPayments/paymentMethods/paymentMethodsList',
                pathMatch: 'full',
            },
            {
                path: 'newPaymentMethods',
                loadChildren: () =>
                    import('src/app/modules/payment-methods/new-payment-method/new-payment-method.module').then(
                        (m) => m.NewPaymentMethodModule
                    ),
            },
            {
                path: 'paymentMethodsList',
                component: PaymentMethodsComponent,
                data: { breadcrumb: { skip: true } },
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class PaymentMethodsRoutingModule {}
