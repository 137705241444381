<div id="cart-summary">
    <mat-card appearance="outlined">
        @if (totalAmountBelowAboveThreshold) {
        <mat-card-content>
            <app-payment-methods-list [aboveThreshold]="false">
                <div>
                    <div class="d-flex justify-content-between title-container">
                        <div>
                            <ng-container *ngIf="totalAmountAboveThreshold">
                                {{isThereInvoicesInCart ? 'Invoices selected for payment': 'Transactions eligible for
                                SprintPay Net Terms'}}
                            </ng-container>
                        </div>
                        <div>USD {{totalAmountBelowAboveThreshold | currency}}</div>
                    </div>
                </div>
            </app-payment-methods-list>
            <app-cart-items [aboveThreshold]="false"></app-cart-items>
        </mat-card-content>
        <mat-divider class="m-divider" *ngIf="totalAmountAboveThreshold"></mat-divider>
        } @if (!!totalAmountAboveThreshold) {
        <mat-card-content>
            <app-payment-methods-list [aboveThreshold]="true">
                <div class="d-flex justify-content-between title-container">
                    <div>Transactions not eligible for SprintPay Net Terms</div>
                    <div>USD {{totalAmountAboveThreshold | currency}}</div>
                </div>
            </app-payment-methods-list>
            <app-cart-items [aboveThreshold]="true"></app-cart-items>
        </mat-card-content>
        }
    </mat-card>
</div>
