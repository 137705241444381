<div class="d-flex flex-grow-1" [ngClass]="{'flex-column': paymentSelectedName === paymentMethodsEnum.CARGO_CREDIT}">
    <div class="d-flex flex-row align-items-center">
        <ng-container *ngIf="paymentSelectedName === paymentMethodsEnum.CREDIT_CARD; else imageContainer">
            <ng-container *ngIf="!!paymentMethodValue; else noCardFound">
                <app-card-type class="me-2" [cardType]="paymentMethodImage" width="2.3em"></app-card-type
            ></ng-container>
        </ng-container>
        <ng-template #imageContainer>
            <app-logo-payment-method
                [paymentMethodName]="paymentMethodImage"
                [width]="'2.3em'"
            ></app-logo-payment-method>
        </ng-template>
        <ng-container *ngIf="showValue">
            <ng-container *ngIf="paymentSelectedName === paymentMethodsEnum.CARGO_CREDIT">
                <div class="ms-2 m-0 mat-subtitle-1 d-flex flex-row align-items-center">Bill my SprintPay account</div>
            </ng-container>
            <ng-container *ngIf="!isCredit && paymentMethodValue && paymentSelectedName !== paymentMethodsEnum.PAYPAL">
                <app-last-four-digits [lastFourDigits]="paymentMethodValue" class="ms-3"></app-last-four-digits>
            </ng-container>
        </ng-container>
        <!-- START: CREDIT CARD SELECTED - NO CARD FOUND -->
        <ng-template #noCardFound>
            <ng-container *ngIf="isAuthenticated; else noCardFoundGuest">
                <div class="ps-1 d-inline-block"><small>Please select a credit card</small></div>
            </ng-container>
            <ng-template #noCardFoundGuest>
                <div class="d-inline-block">
                    <i class="bi bi-exclamation-circle-fill align-items-center me-2 d-inline-block"></i
                    ><small>Please add a credit card</small>
                </div></ng-template
            >
        </ng-template>
        <!-- END: CREDIT CARD SELECTED - NO CARD FOUND -->
        <!-- START: ECHECK SELECTED - NO ECHECK FOUND -->
        <ng-container *ngIf="paymentSelectedName === paymentMethodsEnum.ECHECK && !paymentMethodValue">
            <div class="ps-1 d-inline-block"><small>Please select an e-check</small></div>
        </ng-container>
        <!-- END: ECHECK SELECTED - NO ECHECK FOUND -->
    </div>
    <div *ngIf="isCredit" class="ms-2 m-0 mat-subtitle-1 d-flex flex-row align-items-center">
        {{paymentMethodValue | currency}}
    </div>
    <div
        *ngIf="paymentSelectedName === paymentMethodsEnum.PAYPAL"
        class="ms-2 m-0 mat-subtitle-1 d-flex flex-row align-items-center"
    >
        {{paymentMethodValue}}
    </div>
    @if (isThereInvoicesInCart && isCredit) {
    <p class="error-message mat-body-2 mt-1">
        <b> *SprintPay Net Terms</b> is unavailable when you are paying open invoices. Choose an alternate payment
        method to continue.
    </p>
    } @else if(isEnoughCredit === false && isCredit){
    <div class="d-flex error-message mat-body-2 mt-1">
        <span class="material-symbols-outlined me-1">exclamation</span>You do not have enough credit to make your
        payment.
    </div>
    }
</div>
