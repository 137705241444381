import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, FormGroup, ReactiveFormsModule, ValidationErrors } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BehaviorSubject, take, throwError } from 'rxjs';
import { DynamicFieldDTO } from 'src/app/models/ui/dynamic-field-common.model';
import { FileHandlerService } from 'src/app/services/utils/file-handler.service';
import { PaymentFluxService } from 'src/app/services/utils/payment-flux.service';
import { blackListFiles } from 'src/app/utils/constants';
import Swal from 'sweetalert2';
import { environment } from '../../../../environments/environment';
import { ErrorHandlerService } from '../../../services/utils/error-handler.service';
import { ErrorMatcher } from '../../../utils/error-matcher';
@Component({
    selector: 'app-file',
    standalone: true,
    templateUrl: './file.component.html',
    imports: [CommonModule, ReactiveFormsModule, MatInputModule, MatTooltipModule],
})
export class FileComponent implements OnInit, OnChanges {
    @ViewChild('fileInput') fileInput?: ElementRef<HTMLInputElement>;
    dynamic_required!: boolean;
    showTooltip: boolean;
    matcher: ErrorMatcher = new ErrorMatcher();
    nameFiles: string[];
    isFileLoading: BehaviorSubject<boolean>;
    private _nameFailedFiles: string[];
    private _blackListFile: string[];
    private _loadingFile: boolean;
    private _pathFileArr: string[];
    private readonly _cartURL: String;

    @Input() field!: DynamicFieldDTO;
    @Input() id!: number;
    @Input() dynamicFormGroup!: FormGroup;

    constructor(
        private _paymentFluxService: PaymentFluxService,
        private _errorHandlerService: ErrorHandlerService,
        private _fileService: FileHandlerService
    ) {
        this._cartURL = environment.uris.method.cart;
        this._pathFileArr = [];
        this._loadingFile = false;
        this._blackListFile = blackListFiles;
        this.nameFiles = [];
        this._nameFailedFiles = [];
        this.showTooltip = false;
        this.isFileLoading = new BehaviorSubject<boolean>(false);
    }

    ngOnInit(): void {
        const dynamicValues = this._paymentFluxService.getDynamicValues();
        if (dynamicValues) {
            Object.keys(dynamicValues).forEach((key: string) => {
                if (key === this.field.id?.toString()) {
                    this.dynamicFormGroup.get(this.field.name)?.setValue(dynamicValues[key]);
                    let path = (this._pathFileArr = dynamicValues[key] ? dynamicValues[key].split(',') : []);
                    this.dynamicFormGroup.get(this.field.name)!.setValue(path.join(','));
                    path.forEach((element: string) => {
                        let aux = element.split('/');
                        this.nameFiles.push(aux[aux.length - 1]);
                    });
                }
            });
        }
        this._checkValidations();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['formValidState'] !== undefined) {
            if (changes['formValidState'].currentValue !== undefined) {
                if (changes['formValidState'].currentValue !== changes['formValidState'].previousValue) {
                    if (this.dynamicFormGroup !== undefined) {
                        this.dynamicFormGroup.get(this.field.name)?.markAsTouched();
                    }
                }
            }
        }
    }

    /**
     * @method _checkValidations()
     * @description Review and set the validators for the field
     */

    private _checkValidations(): void {
        this.dynamic_required = this.field.inputValidations
            ? this.field.inputValidations['required']
                ? (this.field.inputValidations['required'].value as boolean)
                : false
            : false;
        this.dynamicFormGroup.get(this.field.name)?.updateValueAndValidity();
    }

    /**
     * @method dropHandler()
     * @param (event: any)
     * @description drop files into area
     */

    dropHandler(event: any) {
        event.preventDefault();
        if (event.dataTransfer.files) {
            this.handleFileInput(event.dataTransfer);
        }
        this._removeDragData(event);
    }

    /**
     * @method handleFileInput()
     * @param (event: any)
     * @description Save and handle the files
     */

    handleFileInput(event: any): void {
        const maxFiles: number = Number(this.field.code?.['maxfiles']?.value) || 0;
        const acceptFile: string = (this.field.attribute?.['accept']?.value as string) || '';
        const sizeFile: number = Number(this.field.code?.['weight']?.value) || 1;
        event = event['files'];
        let nameTempFilesArr: string[] = [];
        this._nameFailedFiles = [];
        if (event.length > maxFiles || event.length + this.nameFiles.length > maxFiles) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text:
                    'You may upload at most ' + maxFiles + ' files ' + acceptFile + ', of max ' + sizeFile + 'MB each.',
            });
        } else {
            for (let i = 0; i < event.length; i++) {
                if (!this._verifyFileExtensions(event.item(i).name)) {
                    nameTempFilesArr.push(event.item(i).name.replace(/ /g, '_'));
                    if (event[i].size / 1024 >= 2000 * sizeFile) {
                        Swal.fire({
                            html: `${this._errorHandlerService.errorTemplate({
                                errors: ['Please select a file with less than ' + sizeFile + ' MB'],
                            })}`,
                            icon: 'error',
                            showConfirmButton: true,
                            confirmButtonText: 'OK',
                            showCancelButton: false,
                            allowOutsideClick: false,
                        })
                            .then()
                            .catch((error: Error): void => {
                                throwError(() => error);
                            });
                        this._nameFailedFiles.push(nameTempFilesArr.shift()!);
                        continue;
                    }
                    let formData = new FormData();
                    formData.append('file', event.item(i));
                    this._loadingFile = true;
                    this.isFileLoading.next(true);
                    this._fileService
                        .uploadFile(formData)
                        .pipe(take(1))
                        .subscribe({
                            next: (response: string) => {
                                if (response) {
                                    this._pathFileArr.push(response);
                                    this.dynamicFormGroup.get(this.field.name)?.setValue(this._pathFileArr.join(','));
                                    this.dynamicFormGroup.get(this.field.name)?.markAsTouched();
                                    let arrayPath = response.split('/');
                                    this.nameFiles.push(arrayPath[arrayPath.length - 1]);
                                    nameTempFilesArr.shift();
                                }
                                this._loadingFile = false;
                                this.isFileLoading.next(false);
                            },
                            error: (error: any) => {
                                if (error.error && typeof error.error == 'string') {
                                    error.error = JSON.parse(error.error);
                                }
                                if (error.error.errors && error.error.errors.length >= 1) {
                                    nameTempFilesArr.shift();
                                    Swal.fire({
                                        html: `${this._errorHandlerService.errorTemplate(error.error)}`,
                                        icon: 'error',
                                        showConfirmButton: true,
                                        confirmButtonText: 'OK',
                                        showCancelButton: false,
                                        allowOutsideClick: false,
                                    })
                                        .then()
                                        .catch((error: Error): void => {
                                            throwError(() => error);
                                        });
                                } else {
                                    Swal.fire({
                                        html: `${this._errorHandlerService.errorMsg(error.error)}`,
                                        icon: 'error',
                                        showConfirmButton: true,
                                        confirmButtonText: 'OK',
                                        showCancelButton: false,
                                        allowOutsideClick: false,
                                    })
                                        .then()
                                        .catch((error: Error): void => {
                                            throwError(() => error);
                                        });
                                    this._nameFailedFiles.push(nameTempFilesArr.shift()!);
                                }
                                this._loadingFile = false;
                                this.isFileLoading.next(false);
                            },
                        });
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'You file contains different extensions.',
                    });
                }
            }
        }
    }

    /**
     * @method removeFile()
     * @param (index: number)
     * @description removed the drag files
     */

    removeFile(index: number): void {
        this._fileService
            .removeFile(this._pathFileArr[index])
            .pipe(take(1))
            .subscribe({
                next: () => {
                    this.nameFiles.splice(index, 1);
                    this._pathFileArr.splice(index, 1);
                    if (this.nameFiles.length < 1) {
                        this.nameFiles = [];
                        this._pathFileArr = [];
                        this.dynamicFormGroup.get(this.field.name)?.setValue(null);
                        this.dynamicFormGroup.value[this.field.name] = null;
                        if (this.fileInput?.nativeElement) {
                            this.fileInput.nativeElement.value = '';
                        }
                    } else {
                        this.dynamicFormGroup.get(this.field.name)?.setValue(this._pathFileArr.join(','));
                    }
                },
                error: (error: any) => {
                    Swal.fire({
                        html: `${this._errorHandlerService.errorMsg(error.error)}`,
                        icon: 'error',
                        showConfirmButton: true,
                        confirmButtonText: 'OK',
                        showCancelButton: false,
                        allowOutsideClick: false,
                    });
                },
            });
    }

    /**
     * @method dragOverHandler()
     * @param (event: any)
     * @description drag files
     */

    dragOverHandler(event: any): void {
        event.preventDefault();
    }

    /**
     * @method _removeDragData()
     * @param (event: any)
     * @description removed the drag files
     */

    private _removeDragData(event: any): void {
        event.dataTransfer.items ? event.dataTransfer.items.clear() : event.dataTransfer.clearData();
    }

    /**
     * @method _verifyFileExtensions()
     * @param (fileName: string)
     * @description Verify the file extensions
     */

    private _verifyFileExtensions(fileName: string): boolean {
        let extensionContainsBlackListFile: boolean = false;
        fileName.split('.').map((item: string): void => {
            if (this._blackListFile.indexOf(item.toLowerCase()) !== -1) {
                extensionContainsBlackListFile = true;
            }
        });
        return extensionContainsBlackListFile;
    }

    /**
     * @method getFormControls()
     * @description
     */

    getFormControls(): { [key: string]: AbstractControl } {
        if (this._loadingFile) {
            Object.keys(this.dynamicFormGroup.controls).forEach((key) => {
                const controlErrors: ValidationErrors | null | undefined = this.dynamicFormGroup.get(key)?.errors;
                if (controlErrors != null) {
                    Object.keys(controlErrors).forEach((): void => {
                        this._loadingFile = false;
                    });
                }
            });
        }
        return this.dynamicFormGroup.controls;
    }
}
