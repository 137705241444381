<div class="cart-invoice">
    <div class="d-flex flex-column flex-sm-row justify-content-between">
        <div class="d-flex align-items-center mb-1 mb-sm-0">
            <mat-icon class="material-symbols-outlined">receipt</mat-icon>
            &nbsp;
            <div class="mat-subtitle-1 name">INV{{invoiceNumber}}</div>
        </div>
        <app-cart-buttons [paymentCart]="paymentRequest"></app-cart-buttons>
    </div>
    <div class="d-flex flex-column-reverse flex-md-row justify-content-between cart-invoice-awb">
        <div class="mat-caption title d-flex flex-grow-1">
            MAWB: <span class="information">{{paymentRequest.awb}}</span>
        </div>
        <div class="d-flex mat-subtitle-1 amount flex-1 order-2 mb-2 mb-sm-0">
            USD {{paymentRequest.amount | currency}}
        </div>
    </div>
    <mat-divider></mat-divider>
    <div class="mat-caption title mt-3" *ngIf="paymentType">
        Type: <span class="information">{{paymentType}} </span>
    </div>
</div>
