import { Injectable } from '@angular/core';
import {
    PaymentMethod,
    PaymentMethods,
    PaymentMethodsType,
    PaymentToken,
    ProcessorName,
} from '@cargos/sprintpay-models';
import { BehaviorSubject, Observable, combineLatest, distinctUntilChanged, map, of, switchMap } from 'rxjs';
import { AddressInformation, PersonalInformation } from 'src/app/models/customer/types';
import { PaymentMethodSelected } from '../../utils/cart-types';
import { PaymentMethodsService } from '../payment-methods.service';
import { CartBillService } from '../utils/cart/cart-service';
import {
    PaymentMethodComplete,
    PaymentMethodGuest,
    PaymentMethodsSelected,
    TermsOfUseForm,
    termsOfUseValidInitialValues,
} from './models/types';

@Injectable({
    providedIn: 'root',
})
export class SummaryService {
    private paymentMethodSelected: BehaviorSubject<PaymentMethodSelected | null> =
        new BehaviorSubject<PaymentMethodSelected | null>(null);
    private paymentMethodSelectedAboveThreshold: BehaviorSubject<PaymentMethodSelected | null> =
        new BehaviorSubject<PaymentMethodSelected | null>(null);
    private guestPaymentInformation: BehaviorSubject<PaymentMethodGuest | null> =
        new BehaviorSubject<PaymentMethodGuest | null>(null);
    private termsOfUseValid = new BehaviorSubject<TermsOfUseForm>(termsOfUseValidInitialValues);

    constructor(
        private paymentMethodsService: PaymentMethodsService,
        private cartBillService: CartBillService
    ) {}

    /**
     * @method setPaymentMethodSelected
     * @param paymentMethodSelected - The selected payment method to be set when the bill amount is above the threshold.
     * @description Sets the selected payment method when the bill amount is above the threshold.
     */
    setPaymentMethodSelectedAboveThreshold(paymentMethodSelected: PaymentMethodSelected | null): void {
        this.paymentMethodSelectedAboveThreshold.next(paymentMethodSelected);
    }

    /**
     * @method instant_payment_method
     * @description Returns the current selected payment method when the bill amount is above the threshold.
     * @returns The currently selected payment method or null if none is selected when the bill amount is above the threshold.
     */
    get instant_payment_method_above_threshold(): PaymentMethodSelected | null {
        return this.paymentMethodSelectedAboveThreshold.value;
    }

    /**
     * @method setPaymentMethodSelected
     * @param paymentMethodSelected - The selected payment method to be set.
     * @description Sets the selected payment method.
     */
    setPaymentMethodSelected(paymentMethodSelected: PaymentMethodSelected | null): void {
        this.paymentMethodSelected.next(paymentMethodSelected);
    }

    /**
     * @method instant_payment_method
     * @description Returns the current selected payment method.
     * @returns The currently selected payment method or null if none is selected.
     */
    get instant_payment_method(): PaymentMethodSelected | null {
        return this.paymentMethodSelected.value;
    }

    getPaymentMethodSelected(aboveThreshold?: boolean): PaymentMethodSelected | null {
        if (aboveThreshold) {
            return this.paymentMethodSelectedAboveThreshold.value;
        }
        return this.paymentMethodSelected.value;
    }

    /**
     * @method getPayMethodSelected
     * @description Returns an observable of the selected payment method.
     * @returns An observable of the selected payment method, distinct until changed.
     */
    getPaymentMethodSelected$(aboveThreshold?: boolean): Observable<PaymentMethodSelected | null> {
        return of(aboveThreshold).pipe(
            switchMap((isCartAboveThreshold) => {
                if (isCartAboveThreshold) {
                    return this.paymentMethodSelectedAboveThreshold.asObservable().pipe(distinctUntilChanged());
                }
                return this.paymentMethodSelected.asObservable().pipe(distinctUntilChanged());
            })
        );
    }

    /**
     * @method instant_guest_payment_information()
     * @description Return current guestPaymentInformation value
     */

    get instant_guest_payment_information(): PaymentMethodGuest | null {
        return this.guestPaymentInformation.value;
    }

    /**
     * @method guestCustomerInformation()
     * @description Return guestCustomerInformation value
     */

    getGuestPaymentInformation$(): Observable<PaymentMethodGuest | null> {
        return this.guestPaymentInformation.asObservable();
    }

    /**
     * @method setGuestPaymentInformation
     * @param paymentMethodGuest - The guest payment method to be set.
     * @description Sets the guest payment method.
     */
    setGuestPaymentInformation(paymentMethodGuest: PaymentMethodGuest | null): void {
        this.guestPaymentInformation.next(paymentMethodGuest);
    }

    /**
     * @method guestCustomerInformation()
     * @description Return guestCustomerInformation value
     */

    getGuestCustomerInformation$(): Observable<PersonalInformation | null> {
        return this.getGuestPaymentInformation$().pipe(
            map((guestPayment) => guestPayment?.personalInformation || null)
        );
    }

    /**
     * @method instant_guest_personal_information()
     * @description Return current guestPersonalInformation value
     */

    get instant_guest_personal_information(): PersonalInformation | null {
        return this.instant_guest_payment_information?.personalInformation || null;
    }

    /**
     * @method guestAddressInformation()
     * @description Return guestAddressInformation value
     */

    getGuestAddressInformation(): Observable<AddressInformation | null> {
        return this.getGuestPaymentInformation$().pipe(map((guestPayment) => guestPayment?.addressInformation || null));
    }

    /**
     * @method instant_guest_address_information()
     * @description Return current guestAddressInformation value
     */

    get instant_guest_address_information(): AddressInformation | null {
        return this.instant_guest_payment_information?.addressInformation || null;
    }

    /*
     * @method clearGuestData()
     * @description Clear guest information
     */

    clearGuestData(): void {
        this.setGuestPaymentInformation(null);
    }

    setTermsOfUseValid(termsOfUseForm: TermsOfUseForm | null): void {
        if (termsOfUseForm) {
            this.termsOfUseValid.next(termsOfUseForm);
        } else {
            this.termsOfUseValid.next(termsOfUseValidInitialValues);
        }
    }

    getTermsOfUseValid$(): Observable<TermsOfUseForm> {
        return this.termsOfUseValid.asObservable();
    }

    isTermsOfUseValid$(): Observable<boolean> {
        return this.getTermsOfUseValid$().pipe(
            map((termsOfUseValid) => {
                return termsOfUseValid.isValidForm;
            }),
            distinctUntilChanged()
        );
    }

    /**
     *
     * @returns The payment method selected with all their details
     *
     */
    getPaymentMethodSelectedComplete$(aboveThreshold?: boolean): Observable<PaymentMethodComplete | null> {
        return this.getPaymentMethodSelected$(aboveThreshold).pipe(
            switchMap((paymentMethodSelected: PaymentMethodSelected | null) => {
                if (paymentMethodSelected) {
                    const { token } = paymentMethodSelected;

                    return this.paymentMethodsService
                        .getPaymentMethodByTypeAndToken(paymentMethodSelected.method, token)
                        .pipe(
                            map((paymentMethodComplete) => ({
                                paymentMethodComplete,
                                name: paymentMethodSelected.method as PaymentMethodsType,
                                token,
                            }))
                        );
                }

                return of(null);
            })
        );
    }

    getPaymentTokenOfPaymentMethodSelected$(
        aboveThreshold?: boolean,
        isGuest?: boolean,
        isThresholdAndPaymentMethodsUnAvailable?: boolean
    ): Observable<PaymentToken | null> {
        return this.getPaymentMethodSelected$(aboveThreshold).pipe(
            switchMap((method: PaymentMethodSelected | null) => {
                if (!method) {
                    return of(null);
                }

                if (method && !isGuest) {
                    return this.paymentMethodsService.getPaymentToken$(method.method, method?.token);
                }

                if (isThresholdAndPaymentMethodsUnAvailable) {
                    return of(
                        PaymentMethod.fromJson({
                            id: 0,
                            name: PaymentMethods.CREDIT_CARD,
                            paymentToken: {
                                token: null,
                                paymentMethod: null,
                                paymentProcessor: null,
                            },
                        })
                    ).pipe(
                        map((paymentMethod: PaymentMethod | undefined) => {
                            if (!paymentMethod) {
                                return null;
                            }

                            return this.buildGuestPaymentToken(paymentMethod);
                        })
                    );
                }

                return this.paymentMethodsService.getPaymentMethodsByType$(method.method).pipe(
                    map((paymentMethod: PaymentMethod | undefined) => {
                        if (!paymentMethod) {
                            return null;
                        }

                        return this.buildGuestPaymentToken(paymentMethod);
                    })
                );
            })
        );
    }

    getPaymentMethodsSelectedName$(): Observable<PaymentMethodsSelected> {
        return this.getPaymentMethodSelected$(true).pipe(
            switchMap((paymentMethodSelectedAboveThreshold: PaymentMethodSelected | null) => {
                return this.getPaymentMethodSelected$(false).pipe(
                    map((paymentMethodSelected: PaymentMethodSelected | null) => {
                        return {
                            paymentMethodSelected: paymentMethodSelected?.method as PaymentMethodsType,
                            paymentMethodSelectedAboveThreshold:
                                paymentMethodSelectedAboveThreshold?.method as PaymentMethodsType,
                        };
                    })
                );
            })
        );
    }

    getPaymentMethodsSelected$(): Observable<{
        paymentMethodSelected: PaymentMethodSelected | null;
        paymentMethodSelectedAboveThreshold: PaymentMethodSelected | null;
    }> {
        return this.getPaymentMethodSelected$(true).pipe(
            switchMap((paymentMethodSelectedAboveThreshold: PaymentMethodSelected | null) => {
                return this.getPaymentMethodSelected$(false).pipe(
                    map((paymentMethodSelected: PaymentMethodSelected | null) => {
                        return {
                            paymentMethodSelected,
                            paymentMethodSelectedAboveThreshold,
                        };
                    })
                );
            })
        );
    }

    /**
     * @method getPaymentMethodsSelectedBasedOnThreshold$()
     * @description Returns an observable that emits an object with two properties: paymentMethodSelected and paymentMethodSelectedAboveThreshold.
     *              The values of these properties are determined by the cart threshold. If the cart is above the threshold, the paymentMethodSelectedAboveThreshold is the currently selected payment method,
     *              and the paymentMethodSelected is undefined. If the cart is below the threshold, the paymentMethodSelected is the currently selected payment method, and the paymentMethodSelectedAboveThreshold is undefined.
     *              If the cart is exactly at the threshold, the paymentMethodSelected is the currently selected payment method, and the paymentMethodSelectedAboveThreshold is the currently selected payment method for payments above the threshold.
     * @returns {Observable<PaymentMethodsSelected>}
     */
    getPaymentMethodsSelectedBasedOnThreshold$(): Observable<PaymentMethodsSelected> {
        return combineLatest([
            this.cartBillService.isCartAboveThreshold$(),
            this.cartBillService.isCartBelowThreshold$(),
        ]).pipe(
            switchMap(([isCartAboveThreshold, isCartBelowThreshold]) => {
                if (isCartAboveThreshold && isCartBelowThreshold) {
                    return this.getPaymentMethodsSelectedName$();
                }

                if (isCartAboveThreshold && !isCartBelowThreshold) {
                    return this.getPaymentMethodSelected$(true).pipe(
                        map((paymentMethodSelectedAboveThreshold: PaymentMethodSelected | null) => {
                            return {
                                paymentMethodSelected: undefined,
                                paymentMethodSelectedAboveThreshold:
                                    paymentMethodSelectedAboveThreshold?.method as PaymentMethodsType,
                            };
                        })
                    );
                }

                return this.getPaymentMethodSelected$(false).pipe(
                    map((paymentMethodSelected: PaymentMethodSelected | null) => {
                        return {
                            paymentMethodSelected: paymentMethodSelected?.method as PaymentMethodsType,
                            paymentMethodSelectedAboveThreshold: undefined,
                        };
                    })
                );
            })
        );
    }

    buildGuestPaymentToken(paymentMethodSelected: PaymentMethod): PaymentToken {
        const guestPaymentInformation = this.instant_guest_payment_information?.creditCard?.details;
        return {
            token: null,
            paymentMethod: {
                name: paymentMethodSelected.getComponentName(),
                logTime: PaymentMethods.CREDIT_CARD === paymentMethodSelected.name ? 10 : 2,
                componentName: paymentMethodSelected.name,
            },
            paymentProcessor: {
                name:
                    PaymentMethods.CREDIT_CARD === paymentMethodSelected.name
                        ? ProcessorName.Braintree
                        : ProcessorName.Forte,
            },
            paymentMethodNumber: guestPaymentInformation?.lastFour,
            paymentInstitution: guestPaymentInformation?.cardType,
            paymentMethodExpirationMonth: guestPaymentInformation?.expirationMonth,
            paymentMethodExpirationYear: guestPaymentInformation?.expirationYear,
        };
    }
}
