import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PaymentMethodSelected } from 'src/app/utils/cart-types';

@Component({
    selector: 'app-snack-bar-payment-method-add',
    templateUrl: './snack-bar-payment-method-add.component.html',
    styleUrl: './snack-bar-payment-method-add.component.scss',
})
export class SnackBarPaymentMethodAddComponent {
    public paymentMethod: PaymentMethodSelected | null;

    @Input() method: string | undefined;
    @Input() lastFourDigit: string | undefined;
    @Output() eventOnClose: EventEmitter<boolean> = new EventEmitter();

    constructor() {}

    close(): void {
        this.eventOnClose.emit(true);
    }
}
