import { HttpEventType } from '@angular/common/http';
import { AfterViewInit, Component, ComponentRef, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject, Subscription, take, takeUntil } from 'rxjs';
import { SecurityService } from 'src/app/services/utils/security.service';
import { SessionService } from 'src/app/services/utils/session.service';
import { UploadFileHandlerService } from 'src/app/services/utils/upload-file-handler.service';
import { FileService } from 'src/app/standalone-components/file-handler/services/file.service';
import { Action } from 'src/app/utils/bulk-payment-result-types';
import { profileComponents, regex } from 'src/app/utils/constants';
import Swal, { SweetAlertIcon } from 'sweetalert2';
import { BreadcrumbService } from 'xng-breadcrumb';
import { environment } from '../../../../../environments/environment';
import { PaymentModal } from '../../../../models/payments/payment-modal.model';
import { CustomerService } from '../../../../services/utils/customer-handler.service';
import { PaymentFluxService } from '../../../../services/utils/payment-flux.service';
import { BulkPaymentResultComponent } from '../../../../standalone-components/bulk-payment-result/bulk-payment-result.component';
import { ErrorMatcher } from '../../../../utils/error-matcher';

@Component({
    selector: 'app-upload-file-by-port',
    templateUrl: './upload-file-by-port.component.html',
    providers: [FileService],
})
export class UploadFileByPortComponent implements OnInit, AfterViewInit, OnDestroy {
    public matcher: ErrorMatcher;
    public paymentForm!: FormGroup;
    public uploadSecurity: Array<string>;
    public formSubmitted: boolean;
    public progressBarPercentage: number;
    public modalData: PaymentModal;
    public templateURL: string;
    public removingFile!: boolean;
    private _selectedFile!: File | null;
    private _bulkPaymentRequests: any[];
    private _bulkRejectedPaymentRequests: number;
    private _submittedToApproverRequests: number;
    private _addedToCartRequests: number;
    private _isNotStandardFlux: boolean;
    private _customer: any;
    private _subscription: Subscription;
    private _locationId: string;
    private readonly _companyName: string;
    private readonly _paymentsURL: string;
    private readonly _cartURL: string;
    private _unsubscribe$: Subject<void>;

    constructor(
        private _securityService: SecurityService,
        private _router: Router,
        private _formBuilder: FormBuilder,
        private _paymentFluxService: PaymentFluxService,
        private _sessionService: SessionService,
        private _customerService: CustomerService,
        private _breadcrumbService: BreadcrumbService,
        private _viewContainerRef: ViewContainerRef,
        private _uploadFileService: UploadFileHandlerService,
        private _fileService: FileService
    ) {
        this._unsubscribe$ = new Subject<void>();
        this._breadcrumbService.set('@upload-file-by-port', 'Upload file by port');
        this._subscription = Subscription.EMPTY;
        this.matcher = new ErrorMatcher();
        this._cartURL = environment.uris.method.cart;
        this.uploadSecurity = [''];
        this.formSubmitted = false;
        this.progressBarPercentage = 0;
        this.modalData = {
            requestIcon: '',
            requestTitleTxt: '',
            requestStatusCountTxt: '',
            requestStatusTxt: '',
            requestHelpTxt: '',
            requestHelpNoteTxt: '',
            requestUseCase: '',
        };
        this._customer = this._customerService.getCustomer();
        this._companyName = this._customer.approvalLevels ? this._customer.approvalLevels.company.name : '';
        this._locationId = this._customer.approvalLevels ? this._customer.approvalLevels.company.firstLocation?.id : '';
        this._isNotStandardFlux = this._companyName !== '';
        this._paymentsURL = environment.uris.method.payments;
        this.templateURL = `${this._paymentsURL}/download/payment/template?uploadByPort=true`;
        this._cartURL = environment.uris.method.cart;
        this._setFromBuilder();
        this._bulkPaymentRequests = [];
        this._bulkRejectedPaymentRequests = 0;
        this._submittedToApproverRequests = 0;
        this._addedToCartRequests = 0;
        this.clearFileResources();
    }

    ngOnInit(): void {
        const userType = this._securityService.getUserType();
        if (this._securityService.verifyComponentsSecurity(profileComponents.uploadFileByPort)) {
            this.uploadSecurity.push(userType);
        }

        this._setFromBuilder();
        this.setDefaultFormValues();
        this.change(this.paymentForm);
        this.removingFile = false;
    }

    ngAfterViewInit(): void {
        this.change(this.paymentForm);
    }

    ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }

    /**
     * @method setDefaultFormValues()
     * @description Set the form values for initializing
     */
    setDefaultFormValues(): void {
        this.notificationEmail.setValue(
            this._customer && this._customer.notificationEmail ? this._customer.notificationEmail : this._customer.email
        );
    }

    /**
     * @method setFromBuilder()
     * @description Set the form requirements to be a valid submission
     */
    private _setFromBuilder(): void {
        this.paymentForm = this._formBuilder.group({
            notificationEmail: ['', [Validators.required, Validators.minLength(5), Validators.pattern(regex.email)]],
            nameFiles: [null, [Validators.required]],
        });
    }

    /**
     * @method notificationEmail()
     * @description: Convenience getter for easy access to form fields
     */
    get notificationEmail(): FormGroup {
        return this.paymentForm.get('notificationEmail') as FormGroup;
    }

    /**
     * @method nameFiles()
     * @description: Convenience getter for easy access to form fields
     */
    get nameFiles(): FormGroup {
        return this.paymentForm.get('nameFiles') as FormGroup;
    }

    /**
     * @method clearFileResources()
     * @description clear file resource
     */
    clearFileResources(): void {
        this.paymentForm.get('nameFiles')!.setValue(null);
        this.paymentForm.value.nameFiles = null;
        this._paymentFluxService.setData('bulkPaymentByPort', this.paymentForm.getRawValue());
    }

    /**
     * @method change()
     * @param (form: FormGroup)
     * @description save the changed form values
     */
    change(form: FormGroup): void {
        this._paymentFluxService.setData('bulkPaymentByPort', form.getRawValue());
    }

    /**
     * @method showErrorPopup()
     * @param (message: string)
     * @description save the changed form values
     */
    showErrorPopup(message: string): void {
        Swal.fire({
            title: 'Oops...',
            text: message,
            icon: 'error',
        });
    }

    /**
     * @method forceToNext()
     * @description go to cart
     */
    forceToNext(): void {
        this._router.navigate(['/admin/cart']);
        this._sessionService.removeElement('currentPayment');
    }

    /**
     * @method uploadAgain()
     * @description upload file again
     */
    uploadAgain(): void {
        this.progressBarPercentage = 0;
        this._bulkPaymentRequests = [];
        this._bulkRejectedPaymentRequests = 0;
    }

    /**
     * @method goToHome()
     * @description go to home
     */
    goToHome(): void {
        this._sessionService.removeElement('currentPayment');
        this._router.navigate(['/admin/home']);
    }

    /**
     * @method goToPendingApprovals()
     * @description go to pending approval's payment list
     */
    goToPendingApprovals(): void {
        this._sessionService.removeElement('currentPayment');
        this._router.navigate(['/admin/facilityPayments/pendingApproval/paymentList']);
    }

    /**
     * @method returnToHome()
     * @description
     */

    returnToHome(): void {
        this._router.navigate(['/admin/home']);
    }

    /**
     * @method addToCart()
     * @param (form: any)
     * @description add to cart
     */
    addToCart(form: FormGroup): void {
        const standardApiURL = `/customer/megaFileUpload?notificationEmail=${this.notificationEmail.value}`;
        const approveFluxApiURL = `/bulk/startApprovalFlow?notificationEmail=${this.notificationEmail.value}${
            !!this._locationId ? `&locationId=${this._locationId}` : ''
        }`;
        const fluxApiUrl = `${this._cartURL}${this._isNotStandardFlux ? approveFluxApiURL : standardApiURL}`;
        this.formSubmitted = true;

        if (form.valid) {
            let formData = new FormData();
            this._selectedFile && formData.append('file', this._selectedFile);
            this.progressBarPercentage = 1;
            this._uploadFileService
                .submitUploadedFile(fluxApiUrl, formData)
                .pipe(takeUntil(this._unsubscribe$))
                .subscribe({
                    next: (response: any) => {
                        if (response.type === HttpEventType.UploadProgress) {
                            this.progressBarPercentage = Math.round((100 * response.loaded) / response.total);
                        }
                        if (response.type === HttpEventType.Response) {
                            this.progressBarPercentage = 100;
                            this._bulkPaymentRequests = JSON.parse(response.body);
                            this._bulkRejectedPaymentRequests = response.status.failed;
                            this._submittedToApproverRequests = response.status.submitted;
                            this._addedToCartRequests = response.status.addedToCart;

                            this.paymentForm.controls['nameFiles'].setValue(this._selectedFile?.name);
                            this._paymentFluxService.setData('bulkPaymentByPort', this.paymentForm.getRawValue());

                            this._determinePaymentModal();
                            this._bulkPaymentModal();
                        }
                    },
                    error: (err: any) => {
                        Swal.fire({
                            title: 'Alert!',
                            html: err,
                            icon: 'info',
                            width: '34em',
                            showConfirmButton: true,
                            confirmButtonText: 'Continue',
                            confirmButtonColor: '#14bb9c',
                            allowOutsideClick: false,
                        }).then(() => {
                            this._selectedFile = null;
                            this._fileService.setFile(null);
                            this.progressBarPercentage = 0;
                            this.removingFile = true;
                            this.formSubmitted = false;
                        });
                        this.clearFileResources();
                    },
                });
        } else {
            form.markAllAsTouched();
            this._fileService.checkFileFormStatus();
        }
    }

    private _determinePaymentModal(): void {
        this.modalData = {
            requestIcon: '',
            requestTitleTxt: '',
            requestStatusCountTxt: '',
            requestStatusTxt: '',
            requestHelpTxt: '',
            requestHelpNoteTxt: '',
            requestUseCase: '',
        };
        const pmReqsLen = this._bulkPaymentRequests?.length || 0;
        // all rejected
        if (pmReqsLen === this._bulkRejectedPaymentRequests) {
            this.modalData.requestIcon = 'error';
            this.modalData.requestTitleTxt = this._isNotStandardFlux
                ? 'Error: your file was rejected'
                : 'Warning: your file has an error(s)';
            this.modalData.requestStatusCountTxt = !this._isNotStandardFlux
                ? `${this._bulkRejectedPaymentRequests} of ${pmReqsLen}`
                : '';
            this.modalData.requestStatusTxt = !this._isNotStandardFlux ? 'payments have not been sent.' : '';
            this.modalData.requestHelpTxt = 'See below for details.';
            this.modalData.requestUseCase = this._isNotStandardFlux ? 'usecase4' : 'standardFlux2';
        }
        // all succeed for enterprise requests submitted
        if (this._isNotStandardFlux && this._submittedToApproverRequests === pmReqsLen) {
            this.modalData.requestIcon = 'success';
            this.modalData.requestTitleTxt = 'Requests sent';
            this.modalData.requestStatusCountTxt = `${pmReqsLen}`;
            this.modalData.requestStatusTxt = 'requests have been sent.';
            this.modalData.requestUseCase = 'usecase3';
        }
        // all succeed for enterprise requests added to cart
        if (this._isNotStandardFlux && pmReqsLen === this._addedToCartRequests) {
            this.modalData.requestIcon = 'success';
            this.modalData.requestTitleTxt = 'Payments sent';
            this.modalData.requestStatusCountTxt = `${pmReqsLen}`;
            this.modalData.requestStatusTxt = 'payments have been sent to your cart.';
            this.modalData.requestUseCase = 'usecase5';
        }
        // all succeed for standard
        if (!this._isNotStandardFlux && this._bulkRejectedPaymentRequests === 0) {
            this.modalData.requestIcon = 'info';
            this.modalData.requestTitleTxt = 'Payments sent';
            this.modalData.requestStatusCountTxt = `${pmReqsLen}`;
            this.modalData.requestStatusTxt = 'payments have been sent.';
            this.modalData.requestUseCase = 'standardFlux1';
        }
        if (
            this._isNotStandardFlux &&
            this._bulkRejectedPaymentRequests === 0 &&
            this._submittedToApproverRequests !== pmReqsLen
        ) {
            this.modalData.requestIcon = 'info';
            this.modalData.requestTitleTxt = 'Requests sent';
            this.modalData.requestStatusCountTxt = `${pmReqsLen}`;
            this.modalData.requestStatusTxt = 'requests have been sent.';
            this.modalData.requestUseCase = 'usecase1';
        }
        // partially rejected and succeed for enterprise
        if (
            this._isNotStandardFlux &&
            this._bulkRejectedPaymentRequests > 0 &&
            this._bulkRejectedPaymentRequests < pmReqsLen
        ) {
            this.modalData.requestIcon = 'warning';
            this.modalData.requestTitleTxt = 'Requests sent';
            this.modalData.requestStatusCountTxt = `${pmReqsLen - this._bulkRejectedPaymentRequests} of ${pmReqsLen}`;
            this.modalData.requestStatusTxt = 'requests have been sent.';
            this.modalData.requestHelpTxt =
                'If you would like to update the requests with issues, submit a new file with the corrections.';
            this.modalData.requestHelpNoteTxt =
                'remove from your original file the requests that were successful to avoid creating duplicate requests.';
            this.modalData.requestUseCase = this._addedToCartRequests ? 'usecase2' : 'usecase6';
        }
        // partially rejected and succeed for standard
        if (
            !this._isNotStandardFlux &&
            this._bulkRejectedPaymentRequests > 0 &&
            this._bulkRejectedPaymentRequests < pmReqsLen
        ) {
            this.modalData.requestIcon = 'warning';
            this.modalData.requestTitleTxt = 'Warning: your file has an error(s)';
            this.modalData.requestStatusCountTxt = `${this._bulkRejectedPaymentRequests} of ${pmReqsLen}`;
            this.modalData.requestStatusTxt = 'payments have not been sent.';
            this.modalData.requestHelpTxt =
                'If you would like to update the payments with issues, submit a new file with the corrections.';
            this.modalData.requestHelpNoteTxt =
                'remove from your original file the requests that were successful to avoid creating duplicate requests.';
            this.modalData.requestUseCase = 'standardFlux3';
        }
    }

    /**
     * @method _bulkPaymentModal()
     * @description bulk payment modal to show the payment reqeust list
     */
    private _bulkPaymentModal(): void {
        const data = {
            ...this.modalData,
            uploadAgainOutlinedBtnCondition:
                this.modalData.requestUseCase === 'usecase2' ||
                this.modalData.requestUseCase === 'standardFlux2' ||
                this.modalData.requestUseCase === 'standardFlux3',
            goToPendingApprovalsBtnCondition:
                this.modalData.requestUseCase === 'usecase3' || this.modalData.requestUseCase === 'usecase6',
            goToHomeBtnCondition: this.modalData.requestUseCase === 'standardFlux2',
            goToCartBtnCondition:
                this.modalData.requestUseCase === 'usecase1' ||
                this.modalData.requestUseCase === 'usecase2' ||
                this.modalData.requestUseCase === 'usecase5' ||
                this.modalData.requestUseCase === 'standardFlux1' ||
                this.modalData.requestUseCase === 'standardFlux3',
            backToHomeBtnCondition: this.modalData.requestUseCase === 'usecase3',
            uploadAgainBtnCondition:
                this.modalData.requestUseCase === 'usecase4' || this.modalData.requestUseCase === 'usecase6',
            data: this._bulkPaymentRequests,
        };

        const component: ComponentRef<BulkPaymentResultComponent> =
            this._viewContainerRef.createComponent(BulkPaymentResultComponent);
        component.instance.data = data;

        Swal.fire({
            icon: this.modalData.requestIcon as SweetAlertIcon,
            width: '45em',
            html: component.location.nativeElement,
            showCancelButton: false,
            showConfirmButton: false,
            showCloseButton: true,
            allowOutsideClick: false,
            didOpen: () => {
                component.instance.callAction.pipe(take(1)).subscribe((action: Action) => {
                    Swal.close();
                    switch (action) {
                        case 'uploadAgain':
                            this.uploadAgain();
                            break;
                        case 'goToPendingApprovals':
                            this.goToPendingApprovals();
                            break;
                        case 'goToHome':
                            this.goToHome();
                            break;
                        case 'forceToNext':
                            this.forceToNext();
                            break;
                    }
                });
            },
        }).then(() => {
            this._selectedFile = null;
            this._fileService.setFile(null);
            this.progressBarPercentage = 0;
            this.clearFileResources();
            this.removingFile = true;
            this.formSubmitted = false;
        });
    }

    /**
     * @method handleFileEvent()
     * @param (event: any)
     * @description save and handle the files
     */
    handleFileEvent(event: File | null): void {
        if (event) {
            this.paymentForm.controls['nameFiles'].setValue(event.name);
            this._selectedFile = event;
            this.removingFile = false;
        } else {
            this.clearFileResources();
        }
    }
}
