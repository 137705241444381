import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { PaymentMethods, PaymentMethodsType } from '@cargos/sprintpay-models';
import { HostedFieldsTokenizePayload } from 'braintree-web';
import { Subject, takeUntil } from 'rxjs';
import { PaymentMethodGuest } from 'src/app/services/summary/models/types';
import { SummaryService } from 'src/app/services/summary/summary.service';
import { LastFourDigitsComponent } from 'src/app/standalone-components/last-four-digits/last-four-digits.component';
import { CardTypeComponent } from 'src/app/standalone-components/logo-credit-card/card-type.component';
import { ExpirationDateComponent } from '../credit-card-list/expiration-date/expiration-date.component';
import { GuestCreditMaterialModule } from './guest-credit-material-modules.module';

@Component({
    selector: 'app-guest-credit-card',
    standalone: true,
    imports: [
        CommonModule,
        GuestCreditMaterialModule,
        CardTypeComponent,
        LastFourDigitsComponent,
        ExpirationDateComponent,
    ],
    templateUrl: './guest-credit-card.component.html',
    styleUrl: './guest-credit-card.component.scss',
})
export class GuestCreditCardComponent implements OnInit, OnDestroy {
    public creditCard: HostedFieldsTokenizePayload | null = null;
    public PaymentMethodsEnum = PaymentMethods;
    public paymentMethodSelected: PaymentMethodsType | null = null;
    private unsubscribe$: Subject<void> = new Subject<void>();

    /**
     * This variable do not define is the cart is above the threshold, is a configuration to get the payment method selected based on section
     */
    @Input() aboveThreshold: boolean = false;
    @Output() eventOnSelectCreditCard: EventEmitter<string> = new EventEmitter<string>();

    constructor(
        private summaryService: SummaryService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.subscribePaymentMethodSelected();
        this.subscribeCreditCard();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    subscribeCreditCard(): void {
        this.summaryService
            .getGuestPaymentInformation$()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (paymentMethodGuest: PaymentMethodGuest | null) => {
                    this.creditCard = paymentMethodGuest?.creditCard || null;
                    if (this.creditCard?.nonce) {
                        this.selectPaymentMethod();
                    }
                },
            });
    }

    selectPaymentMethod(): void {
        this.eventOnSelectCreditCard.emit(undefined);
    }

    subscribePaymentMethodSelected(): void {
        this.summaryService
            .getPaymentMethodSelected$(this.aboveThreshold)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((paymentMethodSelected) => {
                this.paymentMethodSelected = paymentMethodSelected?.method || null;
            });
    }

    editCard(): void {
        this.router.navigate(['./admin/cart/newPaymentMethods/credit-card']);
    }
}
