<!--START: PAYMENT TYPE -->
<form class="row" [formGroup]="paymentForm">
    <div class="form-group">
        <mat-form-field class="d-block" appearance="outline">
            <mat-label>What's this payment for?</mat-label>
            <mat-select
                id="paymentType"
                formControlName="paymentType"
                [errorStateMatcher]="matcher"
                [disabled]="disabledInput"
                (selectionChange)="change($event)"
            >
                <mat-option *ngFor="let item of paymentTypes; trackBy: trackBy" [value]="item"> {{item}} </mat-option>
            </mat-select>
            <mat-error *ngIf="paymentForm.controls['paymentType'].hasError('required')">
                <i class="bi bi-exclamation-triangle-fill me-2"></i>Required
            </mat-error>
        </mat-form-field>
    </div>
</form>
<!--END: PAYMENT TYPE-->
