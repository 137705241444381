import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { from, map, of, switchMap, take, throwError } from 'rxjs';
import { CartBillService, InitialConfigService } from 'src/app/services';
import { PaymentService } from 'src/app/services/payment.service';
import { SummaryValidationService } from 'src/app/services/summary-validation.service';
import { ErrorHandlerService } from 'src/app/services/utils/error-handler.service';
import { SessionService } from 'src/app/services/utils/session.service';
import { Sections } from 'src/app/utils/sections';
import Swal from 'sweetalert2';
import { BreadcrumbService } from 'xng-breadcrumb';
import { environment } from '../../../../../../environments/environment';
import { PaymentToRequestEditService } from '../../../../../services/payment-to-request-edit.service';
import { RequestService } from '../../../../../services/requests.service';
import { RestService } from '../../../../../services/rest.service';
import { CustomerService } from '../../../../../services/utils/customer-handler.service';
import { PaymentFluxService } from '../../../../../services/utils/payment-flux.service';
import { companyName, sprintPaySource } from '../../../../../utils/constants';

@Component({
    selector: 'app-payment-detail',
    templateUrl: './payment-detail.component.html',
})
export class PaymentDetailComponent implements OnInit, OnDestroy {
    id: string;
    paymentDetail: any;
    paymentLogs: any;
    paymentDetailFinish: boolean;
    files: any;
    nameFiles: any[];
    approverActions: boolean;
    submitButtonLabel: string;
    lastApprovalLevel: boolean;
    noInformation: boolean | undefined;
    noInformationMessage: string;
    isSameCustomerAndRequestor: boolean;
    paymentDetailsSection: any;
    paymentCustomerReference: any;
    readonly rejectedAvailable: boolean;
    readonly isStaringLevel: boolean;
    readonly isRequestor: boolean;
    readonly isApprover: boolean;
    readonly companyNames: any;
    private dynamicFieldsReplaced: any;
    private _approvalStatus!: string;
    private readonly _customer: any;
    private readonly _editableVoucher: boolean;
    private readonly _companyType: string;
    private readonly _cartUrl: string;
    private readonly _currentPayment: any;

    constructor(
        private _paymentToRequestEditService: PaymentToRequestEditService,
        private _sessionService: SessionService,
        private _breadcrumbService: BreadcrumbService,
        private _activatedRoute: ActivatedRoute,
        private _restService: RestService,
        private _ngxSpinnerService: NgxSpinnerService,
        private _matDialog: MatDialog,
        private _customerService: CustomerService,
        private _router: Router,
        private _errorHandlerService: ErrorHandlerService,
        private _paymentFluxService: PaymentFluxService,
        private _paymentService: PaymentService,
        private _requestService: RequestService,
        private initialConfigService: InitialConfigService,
        private cartBillService: CartBillService,
        private summaryValidationService: SummaryValidationService
    ) {
        this._breadcrumbService.set('@pending-approval', 'Pending approval(s)');
        this._breadcrumbService.set('@payment-detail', 'Payment detail');
        this.id = '';
        this.nameFiles = [];
        this.noInformation = undefined;
        this.noInformationMessage = '';
        this.approverActions = false;
        this._customer = this._customerService.getCustomer();
        this._currentPayment = this._paymentFluxService.getCurrentPayment();
        this.submitButtonLabel = '';
        this.rejectedAvailable = false;
        this.isStaringLevel = !!this._sessionService.getElement('startingLevel');
        this.isRequestor = !!this._customerService.getCustomer().userType.includes('REQUESTOR');
        this.isApprover = !!this._customerService.getCustomer().userType.includes('APPROVER');
        this.lastApprovalLevel = false;
        this.companyNames = companyName;
        this._companyType = this._customer.approvalLevels ? this._customer.approvalLevels.company.name : '';
        this.paymentDetailFinish = false;
        this._cartUrl = environment.uris.method.cart;
        this.isSameCustomerAndRequestor = false;
        const editableReferences = this._customer.approvalLevels?.company?.paymentReference;
        const isEditableVoucher = editableReferences.filter((item: any): boolean => item.name == 'Voucher #');
        this._editableVoucher = !!(editableReferences && isEditableVoucher.length > 0);
        this.getAllFieldsWithReplacement();
    }

    ngOnInit(): void {
        this._activatedRoute.params.pipe(take(1)).subscribe({
            next: (params: any): void => {
                this.id = params['id'];
                this._approvalStatus = params['approvalStatus'];
            },
            error: (error: Error): void => {
                throwError(() => error);
            },
        });
        if (this._currentPayment) {
            const type: string =
                this._currentPayment[`${encodeURIComponent(this._companyType.toLowerCase())}CustomerReference`]
                    .paymentAmount;
            const approvalLevels = this._customer?.approvalLevels?.canCreateApprovalFlow?.filter((item: any) =>
                item.name.includes(type)
            );
            this.paymentDetail = this._currentPayment;
            this.isSameCustomerAndRequestor = this.paymentDetail.originalRequestor?.id === this._customer?.id;
            this._getPaymentLogs();
            this.settingForApproverActions();
            const lengthLevels = approvalLevels[0]?.approvalFlowLevels.length - 1;
            this.lastApprovalLevel =
                this.paymentDetail?.level?.orderId === approvalLevels[0]?.approvalFlowLevels[lengthLevels]?.orderId;
            this.getCurrentApprovalFlowLevel(this.paymentDetail.level, approvalLevels[0]?.approvalFlowLevels);
            const dynamicValues = this._paymentFluxService.getDynamicValues();
            if (dynamicValues) {
                this.paymentDetail['dynamicValues'] = dynamicValues;
            }
            this.getPaymentCustomerReferenceSection();
            this.getPaymentDetailsSection();
        } else {
            this._getPaymentDetail();
        }
    }

    ngOnDestroy(): void {
        this._matDialog.closeAll();
    }

    /**
     * @method settingForApproverActions()
     * @description
     */

    settingForApproverActions(): void {
        if (this._companyType === companyName.schenker) {
            this.approverActions =
                (this.isApprover && this.lastApprovalLevel && !this.isSameCustomerAndRequestor) ||
                !this.paymentDetail.ownerCanPay;
        } else if (this._companyType === companyName.kn || this._companyType === companyName.geodis) {
            this.approverActions = this.isApprover && !this.isSameCustomerAndRequestor;
        } else if (this._companyType === companyName.dhl) {
            this.approverActions = this.isApprover;
        } else if (this._companyType === companyName.emotrans || this._companyType === companyName.rhenus) {
            this.approverActions =
                (this.isApprover && !this.isSameCustomerAndRequestor) || !this.paymentDetail.ownerCanPay;
        } else if (this._companyType) {
            this.approverActions =
                (this.isApprover && !this.isSameCustomerAndRequestor) || !this.paymentDetail.ownerCanPay;
        }
    }

    /**
     * @method getPaymentDetail()
     * @description Get all the logs by payment
     */

    private _getPaymentDetail(): void {
        const url: string = `${this._cartUrl}/payment-request/details/${this.id}?approvalStatus=${this._approvalStatus}`;
        const companyType: string = this._customerService.getCompanyName();
        this._ngxSpinnerService.show();
        this._restService
            .get(url, {})
            .then((result: any): void => {
                const levels: any[] = result.approvalFlowLevel
                    ? result.approvalFlowLevel.approvalFlow?.approvalFlowLevels
                    : [];
                const levelsLength: number = levels.length - 1;
                const uniqueRequestor = !!this._customerService.getCustomer().userType.includes('UNIQUE_REQUESTOR');
                this.lastApprovalLevel = result.approvalFlowLevel?.orderId === levels[levelsLength]?.orderId;
                this.isSameCustomerAndRequestor =
                    result.originalRequestor?.id === this._customer.id &&
                    result.originalRequestor?.name === this._customer.name;
                this.paymentDetail = this._paymentToRequestEditService.getPaymentRequestDataCart(
                    result,
                    '/admin/facilityPayments/pendingApproval/paymentDetail/' + this.id + '/' + result.approvalStatus,
                    companyType.toString()
                );
                this.paymentDetail.createdDate = new Date(result['createdDate']);
                this.paymentDetail.externalData = result['externalData'];
                this.paymentDetail.originalRequestor = result['originalRequestor'];
                this.paymentDetail.customer = result['customer'];
                this.paymentDetail.hawb = result['hawb'];
                this.paymentDetail.level = result['approvalFlowLevel'] || '';
                this.paymentDetail.approvalFlowLevel = result['approvalFlowLevel']
                    ? result['approvalFlowLevel']['approvalFlow']?.['location']?.['name']
                    : '';
                this.paymentDetail.companyType = companyType.toString();
                this.paymentDetail.approvalStatus = 'PENDING';
                this.paymentDetail.approvalLevels = result['approvalFlowLevel']
                    ? result['approvalFlowLevel']['approvalFlow']?.['approvalFlowLevels']
                    : '';
                this.paymentDetail.location = result['location']['name'];
                this.paymentDetail.sprintPaySource = sprintPaySource === result['source']?.['name'].toLowerCase();
                this.paymentDetail.uniqueRequestor = uniqueRequestor;
                this.paymentDetail.startingLevel = this.isStaringLevel;
                this.paymentDetail.ownerCanPay = this.submitToApproverFlux(
                    this._customer.approvalLevels,
                    result.externalData
                );
                this.paymentDetail.sameCustomerAndRequestor = this.isSameCustomerAndRequestor;
                this.paymentDetail.externalLookup = result['facility'] ? result['facility']['externalLookup'] : false;
                this.files = result.attachments ? result.attachments.split(',') : '';
                result.attachments
                    ? result.attachments.split(',').map((item: any): void => {
                          this.nameFiles.push(item.split('/')[5]);
                      })
                    : '';
                this._getPaymentLogs();
                this.getPaymentCustomerReferenceSection();
                this.getPaymentDetailsSection();
                this.settingForApproverActions();
                this.getCurrentApprovalFlowLevel(result.approvalFlowLevel, levels);
            })
            .catch((error: any): void => {
                this.noInformationMessage = error?.error?.errors ? error.error.errors : '';
                this.noInformation = true;
                this._ngxSpinnerService.hide();
            });
    }

    /**
     * @method getCurrentApprovalFlowLevel()
     * @param (currentLevel: any)
     * @param (approvalLevels: any)
     * @description Geta the current approval flow level
     */

    getCurrentApprovalFlowLevel(currentLevel: any, approvalLevels: any): void {
        const currentOrderId: number = currentLevel?.orderId;
        if (this.lastApprovalLevel) {
            this.submitButtonLabel = 'Add to cart';
        } else {
            const nextLevel: any = approvalLevels.filter((item: any): boolean => item.orderId == currentOrderId + 1);
            this.submitButtonLabel =
                nextLevel[0] && nextLevel[0].approvalLevel
                    ? 'Submit to ' + nextLevel[0].approvalLevel.name
                    : 'Submit to approver';
        }
    }

    /**
     * @method _getPaymentLogs()
     * @description Generates the URL based on customer approval level and starting level; also performs the first call to retrieve the size of the array
     */

    private _getPaymentLogs(): void {
        const url: string = `${this._cartUrl}/getLogHistoryByPaymentRequest/${
            this.id
        }?page=0&size=30&date=${new Date().valueOf()}`;

        this._ngxSpinnerService.show();
        this._restService
            .get(url, {})
            .then((result: any): void => {
                this.paymentLogs = result.content.map((item: any) => {
                    return {
                        approval: `${item.approvalLevel}: ${item.firstName} ${item.lastName}`,
                        date: moment(item.date).format('MMM DD, YYYY, h:mm:ss a'),
                        event: item.event,
                        reason: item.reason,
                    };
                });
                this.noInformation = false;
                this._ngxSpinnerService.hide();
            })
            .catch((): void => {
                this._ngxSpinnerService.hide();
            })
            .finally((): void => {
                this.paymentDetailFinish = true;
            });
    }

    /**
     * @method openDialog()
     * @description Opens the dialog in this case menu right sidebar for the activity log
     */

    openDialog(templateRef: any): void {
        this._matDialog.open(templateRef, {
            id: 'timeline',
            disableClose: true,
        });
    }

    /**
     * @method addToCart()
     * @description
     */

    addToCart(): void {
        if (this.lastApprovalLevel) {
            this.summaryValidationService
                .addToCartValidate('paymentRequest')
                .pipe(
                    take(1),
                    switchMap((result) => {
                        if (result.continue) {
                            return of(result);
                        }
                        return from(
                            Swal.fire({
                                html: `<h5>Hey, psst psst...</h5><p>${result.message}</p>`,
                                icon: 'info',
                                showCancelButton: true,
                                confirmButtonText: 'OK',
                                cancelButtonText: 'Cancel',
                                allowOutsideClick: false,
                                reverseButtons: true,
                            })
                        ).pipe(
                            switchMap((response) => {
                                if (response.isConfirmed) {
                                    return this.cartBillService.deleteAndGetCart().pipe(
                                        take(1),
                                        map(() => {
                                            this.sendToCart();
                                            return result;
                                        })
                                    );
                                }

                                return of(result);
                            })
                        );
                    })
                )
                .subscribe({
                    next: (result): void => {
                        if (result.continue) {
                            this.sendToCart();
                        }
                    },
                });
        } else {
            this.sendToCart();
        }
    }

    sendToCart(): void {
        Swal.fire({
            title: 'Please wait...',
            width: '35em',
            text: this.lastApprovalLevel ? `We're adding your request to the cart` : `We're sending your request `,
            timer: 1000,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
        });
        this._restService
            .post(`${this._cartUrl}/approvePaymentRequest?id=${this.id}`, {})
            .then((): void => {
                of(this.lastApprovalLevel)
                    .pipe(
                        switchMap((isLastApprovalLevel) => {
                            if (!isLastApprovalLevel) {
                                return of(null);
                            }
                            return this.initialConfigService.getPaymentMethodSelectedAndGetCart$();
                        }),
                        take(1)
                    )
                    .subscribe();
                setTimeout((): void => {
                    Swal.close();
                    this.lastApprovalLevel
                        ? this._router.navigate(['admin/cart'])
                        : this._router.navigate(['admin/facilityPayments/pendingApproval/paymentList']);
                }, 1000);
            })
            .catch((error: any): void => {
                Swal.close();
                Swal.fire({
                    title: 'Oops!',
                    html: `${this._errorHandlerService.errorMsg(error.error)}`,
                    showConfirmButton: true,
                    confirmButtonText: 'Close',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                }).then((result: any) => {
                    if (result.value) {
                        this._router.navigate(['admin/facilityPayments/pendingApproval/paymentList']);
                    } else {
                        Swal.close();
                    }
                });
            });
    }

    /**
     * @method rejectRequest()
     * @description
     */

    rejectRequest(): void {
        Swal.fire({
            confirmButtonText: 'Reject request',
            confirmButtonColor: 'rgba(255, 77, 77, 1)',
            width: '35em',
            showCancelButton: true,
            title: 'W A R N I N G!',
            text: 'Do you really want to reject this request? This process cannot be undone',
            input: 'textarea',
            icon: 'warning',
            inputPlaceholder: 'Type your reason here',
            reverseButtons: true,
            inputAttributes: {
                maxlength: '180',
            },
            preConfirm: function (result): boolean | any {
                if (result.value == '' || !result) {
                    Swal.showValidationMessage('You need to write something!');
                    return false;
                }
                return result;
            },
        }).then((result: any): void => {
            if (result.value) {
                this._ngxSpinnerService.show();
                const reason: string = result.value;
                const id: number = Number.parseFloat(this.id);
                const url: string = this._cartUrl + '/rejectPaymentRequest?id=' + id + '&reason=' + reason;
                this._restService
                    .post(url, {})
                    .then((): void => {
                        setTimeout((): void => {
                            this._ngxSpinnerService.hide();
                            this._router.navigate(['admin/facilityPayments/pendingApproval/paymentList']);
                        }, 2000);
                    })
                    .catch((error: any): void => {
                        this._ngxSpinnerService.hide();
                        Swal.fire({
                            html: `${this._errorHandlerService.errorMsg(error.error)}`,
                            title: 'Oops!',
                            showConfirmButton: true,
                            showCancelButton: false,
                            confirmButtonText: 'Close',
                            allowOutsideClick: false,
                        }).then((result: any) => {
                            if (result.value) {
                                this._router.navigate(['admin/facilityPayments/pendingApproval/paymentList']);
                            } else {
                                Swal.close();
                            }
                        });
                    });
            }
        });
    }

    /**
     * @method cancelRequest()
     * @description
     */

    cancelRequest(): void {
        Swal.fire({
            confirmButtonText: 'Void request',
            confirmButtonColor: 'rgba(255, 77, 77, 1)',
            width: '35em',
            showCancelButton: true,
            title: 'W A R N I N G!',
            text: 'Do you really want to void this request? This process cannot be undone',
            input: 'textarea',
            icon: 'warning',
            inputPlaceholder: 'Type your reason here',
            reverseButtons: true,
            inputAttributes: {
                maxlength: '250',
            },
            preConfirm: function (result): boolean | any {
                if (result.value == '' || !result) {
                    Swal.showValidationMessage('You need to write something!');
                    return false;
                }
                return result;
            },
        }).then((result: any): void => {
            if (result.value) {
                this._ngxSpinnerService.show();
                const reason: string = encodeURIComponent(result.value);
                const id: number = Number.parseFloat(this.id);
                const url: string = this._cartUrl + '/cancelPaymentRequest?id=' + id + '&reason=' + reason;
                this._restService
                    .post(url, {})
                    .then((): void => {
                        setTimeout((): void => {
                            this._ngxSpinnerService.hide();
                            this._router.navigate(['admin/facilityPayments/pendingApproval/paymentList']);
                        }, 2000);
                    })
                    .catch((error: any): void => {
                        this._ngxSpinnerService.hide();
                        Swal.fire({
                            html: `${this._errorHandlerService.errorMsg(error.error)}`,
                            title: 'Oops!',
                            showConfirmButton: true,
                            showCancelButton: false,
                            confirmButtonText: 'Close',
                            allowOutsideClick: false,
                        }).then((result: any) => {
                            if (result.value) {
                                this._router.navigate(['admin/facilityPayments/pendingApproval/paymentList']);
                            } else {
                                Swal.close();
                            }
                        });
                    });
            }
        });
    }

    /**
     * @method validateActions()
     * @description
     */

    validateActions(): void {
        if (this._editableVoucher) {
            Swal.fire({
                confirmButtonText: 'Next',
                showCancelButton: true,
                input: 'text',
                title: 'Please, enter the Voucher #',
                inputPlaceholder: 'Voucher #',
                reverseButtons: true,
                preConfirm: async (result): Promise<any> => {
                    if (result.value == '' || !result) {
                        Swal.showValidationMessage('You need to enter the voucher number!');
                        return false;
                    } else {
                        return this.updatePayment(result);
                    }
                },
            });
        } else {
            if (this.isStaringLevel && !this.paymentDetail.ownerCanPay && this.isSameCustomerAndRequestor) {
                const dynamicValues = this._paymentFluxService.getDynamicValues();
                const request: object = this._requestService.getRequestData(
                    this.paymentDetail,
                    this._companyType,
                    dynamicValues
                );
                const editURL: string = this._cartUrl + '/editApprovalPaymentRequest';
                this._restService
                    .post(editURL, { data: request })
                    .then((): void => {
                        this.addToCart();
                    })
                    .catch((error: any): void => {
                        Swal.fire({
                            html: `${this._errorHandlerService.errorMsg(error.error)}`,
                            text: 'Something went wrong, try again later',
                            icon: 'error',
                            showConfirmButton: false,
                            showCancelButton: true,
                            cancelButtonText: 'Close',
                            allowOutsideClick: false,
                        });
                    });
            } else {
                this.addToCart();
            }
        }
    }

    /**
     * @method getCurrentApprovalFlowLevel()
     * @param (voucherNumber: string)
     * @description Geta the current approval flow level
     */

    updatePayment(voucherNumber: string): void {
        const editURL: string = this._cartUrl + '/editApprovalPaymentRequest';
        const request: object = {
            id: this.paymentDetail.id,
            awb: this.paymentDetail.awb,
            amount: this.paymentDetail.amount,
            hawb: this.paymentDetail.hawb,
            notes: this.paymentDetail.notes,
            attachments: this.paymentDetail.attachments,
            paymentType: this.paymentDetail.paymentType,
            facility: this.paymentDetail.facility.id,
            notificationEmail: this.paymentDetail.notificationEmail,
            customerReference: this.paymentDetail.customerReference,
            paidTo: this.paymentDetail.paidTo ? this.paymentDetail.paidTo : 'test',
            externalData: {
                id: this.paymentDetail.externalData.id,
                source: this.paymentDetail.externalData.source,
                sourceSystem: this.paymentDetail.externalData.sourceSystem,
                product: this.paymentDetail.externalData.product,
                station: this.paymentDetail.externalData.station,
                type: this.paymentDetail.externalData.type,
                awbId: this.paymentDetail.externalData.awb,
                voucher: voucherNumber,
            },
        };
        this._restService
            .post(editURL, request)
            .then((): void => {
                this.addToCart();
            })
            .catch((error: any): void => {
                Swal.fire({
                    html: `${this._errorHandlerService.errorMsg(error.error)}`,
                    text: 'Something went wrong, try again later',
                    title: 'Oops!',
                    showConfirmButton: false,
                    showCancelButton: true,
                    cancelButtonText: 'Close',
                    allowOutsideClick: false,
                }).then((result: any) => {
                    if (result.value) {
                        this._router.navigate(['admin/facilityPayments/pendingApproval/paymentList']);
                    } else {
                        Swal.close();
                    }
                });
            });
    }

    /**
     * @method submitToApproverFlux()
     * @description
     */

    submitToApproverFlux(approvalLevels: any, externalData: any): boolean {
        const type = externalData ? externalData.type : '';
        const canCreate = approvalLevels.canCreateApprovalFlow.filter((item: any) => item.name.includes(type));
        return canCreate[0] ? canCreate[0].approvalFlowLevels?.length > 1 : true;
    }

    /**
     * @method getAllFieldsWithReplacement()
     * @description
     */

    getAllFieldsWithReplacement(): void {
        if (this._companyType && Object.keys(this.companyNames).indexOf(this._companyType.toLowerCase()) === -1) {
            this.dynamicFieldsReplaced = [];
            this._paymentService.getAllDynamicFieldsWithReplacement().subscribe({
                next: (fieldsReplaced: any): void => {
                    this.dynamicFieldsReplaced = fieldsReplaced;
                },
            });
        }
    }

    getPaymentDetailsSection(): void {
        const companyType: string = this._customerService.getCompanyName();
        this.paymentDetailsSection = Sections.getDetailsOfPaymentDetailsSummarySection(this.paymentDetail, companyType);
    }

    getPaymentCustomerReferenceSection(): void {
        const companyType: string = this._customerService.getCompanyName();
        const customerReferenceLabel = this.dynamicFieldsReplaced?.find((item) => item.name === 'customerRef');
        this.paymentCustomerReference = Sections.getDetailsOfCustomerReferenceSummarySection(
            this.paymentDetail.reference,
            this._customer,
            customerReferenceLabel?.label || '',
            companyType
        );
    }
}
