<!--START: UPLOAD FILE -->
<form [formGroup]="paymentForm">
    <div class="row">
        <div class="d-flex gap-2">
            <p><strong>{{ dynamicField ? dynamicField.label : attachFileLabel}}</strong></p>
            <mat-icon
                class="material-symbols-outlined"
                matTooltip="These attachments are not shared with the facility"
                [matTooltipPosition]="'above'"
            >
                info
            </mat-icon>
        </div>
        <p>
            Choose a file or drag it here. You may upload at most 3 files .doc, .docx, .pdf or image (jpeg, jpg, png),
            of max 1MB each.
        </p>
        <div class="my-3 d-flex justify-content-center">
            <div class="col-12">
                <div
                    id="dragDrop"
                    class="form-group formFile drop-zone-area"
                    (drop)="dropHandler($event)"
                    (dragover)="dragOverHandler($event)"
                    [ngClass]="{'required': paymentForm && getFormControls()['nameFiles'].errors, 'redBorderRequired': paymentForm && getFormControls()['nameFiles'].errors && formValidState === false}"
                >
                    <div class="row">
                        <div class="col-12 d-block text-center">
                            <p><i class="bi bi-cloud-arrow-up me-2"></i>Drag and drop files here or</p>
                        </div>
                        <div class="col-12 my-2 d-flex justify-content-center">
                            <div class="upload-button d-flex justify-content-center align-items-center text-uppercase">
                                upload file
                                <input
                                    #fileInput
                                    type="file"
                                    multiple="multiple"
                                    (change)="handleFileInput($event.target)"
                                    accept=".docx,.doc,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, image/*"
                                />
                            </div>
                        </div>
                        <div class="col-12 my-2 d-flex justify-content-center">
                            <div class="d-flex flex-column flex-md-row">
                                <div
                                    class="justify-content-center align-items-center"
                                    *ngFor="let item of nameFilesArr; let i = index"
                                >
                                    <div class="flex-content upload-file__nameFile">
                                        {{ item }}
                                        <a class="pointer" (click)="removeFile(i)">
                                            <i class="material-symbols-outlined main-icon close">close</i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <mat-error *ngIf="nameFiles?.touched && nameFiles?.errors && (isFileLoading | async) === false">
                    <mat-error *ngIf="nameFiles.hasError('required')" style="font-size: 12px; font-weight: 400">
                        <i class="bi bi-exclamation-triangle-fill me-2"></i>{{
                        dynamicField?.validations?.required?.errorMessage ?
                        dynamicField.validations.required.errorMessage : 'Please attach the required document'}}
                    </mat-error>
                </mat-error>
            </div>
        </div>
    </div>
</form>
<!--END: UPLOAD FILE  -->
