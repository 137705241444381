<!--START: DYNAMIC INPUT FILE FIELD-->
<form [formGroup]="dynamicFormGroup" class="row">
    <div class="d-flex gap-2">
        <p><strong>{{field.label || 'Attach files (Optional)'}}</strong></p>
        <span
            class="material-symbols-outlined"
            matTooltip="These attachments are not shared with the facility"
            [matTooltipPosition]="'above'"
        >
            info
        </span>
    </div>
    <p>
        {{ field.subLabel || 'Choose a file or drag it here. You may upload at most 3 files .doc, .docx, .pdf or image
        (jpeg, jpg, png), of max 1MB each.'}}
    </p>
    <div class="my-3 d-flex justify-content-center">
        <div class="col-12">
            <div
                id="dragDrop"
                class="form-group formFile drop-zone-area"
                (drop)="dropHandler($event)"
                (dragover)="dragOverHandler($event)"
                [ngClass]="{'required': dynamicFormGroup && getFormControls()[field.name].errors, 'redBorderRequired': dynamicFormGroup && getFormControls()[field.name].errors && dynamicFormGroup.touched }"
            >
                <div class="row">
                    <div class="col-12 d-block text-center">
                        <p><i class="bi bi-cloud-arrow-up me-2"></i>Drag and drop files here or</p>
                    </div>
                    <div class="col-12 my-2 d-flex justify-content-center">
                        <div class="upload-button d-flex justify-content-center align-items-center text-uppercase">
                            upload file
                            <input
                                #fileInput
                                [type]="field.fieldType"
                                [required]="dynamic_required"
                                [multiple]=" field.attribute?.['multiple']?.value"
                                (change)="handleFileInput($event.target)"
                                [accept]="field.attribute?.['accept']?.value"
                            />
                        </div>
                    </div>
                    <div class="col-12 my-2 d-flex justify-content-center">
                        <div class="d-flex flex-column flex-md-row">
                            <div
                                class="justify-content-center align-items-center"
                                *ngFor="let item of nameFiles; let i = index"
                            >
                                <div class="flex-content upload-file__nameFile">
                                    {{ item }}
                                    <a class="pointer" (click)="removeFile(i)">
                                        <i class="material-symbols-outlined main-icon close">close</i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--START: ERRORS-->
            <mat-error
                *ngIf="dynamicFormGroup.controls[field.name].touched && dynamicFormGroup.controls[field.name].hasError('required')"
            >
                <i class="bi bi-exclamation-triangle-fill me-2"></i>{{
                field.inputValidations?.['required']?.errorMessage || 'This field it\'s required'}}
            </mat-error>
            <!--END: ERRORS-->
        </div>
    </div>
    <!--START: DYNAMIC TOOLTIP-->
    <ng-container *ngIf="field?.tooltipTitle || field?.tooltipMessage ">
        <ng-container *ngIf="showTooltip === true">
            <div class="col-12 col-lg-12">
                <span class="cs__tooltip cs__tooltip--warning">
                    <strong class="d-inline-block mb-3" *ngIf="field?.tooltipTitle">{{ field.tooltipTitle }}</strong>
                    <span class="d-block" *ngIf="field?.tooltipMessage">{{field.tooltipMessage}}</span>
                </span>
            </div>
        </ng-container>
    </ng-container>
    <!--END: DYNAMIC TOOLTIP-->
</form>
<!--END: DYNAMIC INPUT FIELD-->
