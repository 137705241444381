<div class="cart-rates">
    <ng-container *ngFor="let rate of rates">
        <div class="d-flex flex-row">
            <div class="d-flex flex-grow-1">
                <div class="mat-caption title" *ngIf="rate.value">{{rate.label}}:&nbsp;</div>
            </div>
            <div class="d-flex">
                @if (rate.brackets) {
                <div class="mat-caption information" *ngIf="rate.value">USD ({{rate.value | currency}})</div>
                } @else {
                <div class="mat-caption information" *ngIf="rate.value">USD {{rate.value | currency}}</div>
                }
            </div>
        </div>
    </ng-container>
</div>
