import { Component, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Threshold } from '@cargos/sprintpay-models';
import { Subject, takeUntil } from 'rxjs';
import { CartBillService } from 'src/app/services';

@Component({
    selector: 'app-cart-banner',
    templateUrl: './cart-banner.component.html',
    styleUrl: './cart-banner.component.scss',
})
export class CartBannerComponent implements OnInit, OnDestroy {
    private unsubscribe$: Subject<void> = new Subject<void>();
    public threshold: Threshold | null;
    public helpInformation: string;

    constructor(
        private cartBillService: CartBillService,
        private renderer: Renderer2,
        private elem: ElementRef
    ) {}

    ngOnInit(): void {
        this.subscribeBannerCart();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
    subscribeBannerCart() {
        this.cartBillService
            .getMessageThreshold()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (threshold: Threshold | null) => {
                    this.threshold = threshold;
                    this.helpInformation = threshold?.messageInfo?.replace(/[\n]/g, '') || '';
                    const elementMessage = this.elem.nativeElement.querySelector('.message-body');
                    this.renderer.setProperty(elementMessage, 'innerHTML', `${threshold?.message}`);
                },
            });
    }
}
