import { Injectable, OnDestroy, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment/moment';
import { switchMap } from 'rxjs';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { environment } from '../../environments/environment';
import { termsOfUseURL } from '../utils/constants';
import { CustomerAPIService } from './requests/customer-api.service';
import { RestService } from './rest.service';
import { AuthService } from './utils/auth.service';
import { CustomerService } from './utils/customer-handler.service';
import { ErrorHandlerService } from './utils/error-handler.service';
import { TokenService } from './utils/token.service';
import { UserSessionService } from './utils/user-session.service';

@Injectable({ providedIn: 'root' })
export class TermsOfUseService implements OnDestroy {
    private readonly _authUrl: string;

    constructor(
        private _domSanitizer: DomSanitizer,
        private _errorHandlerService: ErrorHandlerService,
        private _restService: RestService,
        private _authService: AuthService,
        private _customerService: CustomerService,
        private _tokenService: TokenService,
        private _customerAPIService: CustomerAPIService,
        private _userSessionService: UserSessionService
    ) {
        this._authUrl = environment.uris.method.authentication;
    }

    ngOnDestroy(): void {
        Swal.close();
    }

    /**
     * @method validateShowTermsOfUse()
     * @param (customer: any)
     * @description Verify if the user accepted the last term of use
     */

    validateShowTermsOfUse(customer: any): void {
        const effectiveDate = customer && customer.effectiveDate ? customer.effectiveDate : null;
        const date: string = new Date(effectiveDate).toUTCString();
        const dueDate: moment.Moment = moment.utc(date);
        const diffDay: number = dueDate.startOf('day').diff(moment.utc(), 'days');
        const privacyPolicyUrl: string | null = this._domSanitizer.sanitize(
            SecurityContext.RESOURCE_URL,
            this._domSanitizer.bypassSecurityTrustResourceUrl(termsOfUseURL)
        );
        if (!customer.acceptTerms && diffDay < 1) {
            const accessToken: string | null = this._tokenService.getCurrentAccessControl();
            const customerId = JSON.parse(this._customerService.getDecodedToken()?.SPRINT_PAY_CUSTOMER)?.id;
            Swal.fire({
                html: `<img class='mt-3' src='assets/images/sprintpay-horizontal-logo.svg' alt='SrintPay Logo' style='height: 50px: width: 100%'>
           <img src='assets/images/documents.svg' alt=' Ilustration'>
           <h5 class='mt-3' style='text-align: center; color: #032747;'>SprintPay Terms have changed</h5>
           <div style='padding: 1rem 1rem 0 1rem; text-align: center;'>
             <p style='font-weight:400; color: #000000; font-size: 18px;'>
                <strong>As of November 21, 2024, our <a style='color:#14BB9C; text-decoration: underline;' href='${privacyPolicyUrl}' target='_blank'>Terms of Use</a> for SprintPay have been updated </strong> and need to be reviewed by you. By accepting, you acknowledge you’ve read them and agree to abide by their contents.
             </p>
           </div>`,
                showCancelButton: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                showConfirmButton: true,
                confirmButtonColor: '#14BB9C',
                confirmButtonText: 'Accept',
                cancelButtonText: 'Decline',
            }).then((result: SweetAlertResult): void => {
                if (result.value) {
                    const utcDate: string = moment.utc(new Date()).format('YYYY-MM-DD 00:00:00');
                    this._authService
                        .getIPAddress()
                        .pipe(
                            switchMap((ip: string) => {
                                return this._customerAPIService.getCustomerTermsOfUseRequest(
                                    customerId,
                                    ip,
                                    accessToken,
                                    utcDate
                                );
                            })
                        )
                        .subscribe({
                            next: (response: any): void => {
                                if (response && response.authorizationToken && response.refreshToken) {
                                    this._authService.initConfigTokens(response);
                                }
                            },
                            error: (error: any): void => {
                                Swal.fire({
                                    title: 'Oops...',
                                    html: `${this._errorHandlerService.errorMsg(error.error)}`,
                                    icon: 'error',
                                    showConfirmButton: true,
                                    confirmButtonText: 'OK',
                                    confirmButtonColor: '#14bb9c',
                                    allowOutsideClick: false,
                                });
                            },
                        });
                } else {
                    this._userSessionService.logout();
                }
            });
        }
    }
}
