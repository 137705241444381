import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ValidateEmailFormGroupType } from '../models/validate-email-form';

@Injectable({
    providedIn: 'root',
})
export class EmailFormService {
    private emailForm: BehaviorSubject<ValidateEmailFormGroupType | null> =
        new BehaviorSubject<ValidateEmailFormGroupType | null>(null);
    private email: string = '';

    get instant_emailForm(): ValidateEmailFormGroupType | null {
        return this.emailForm.value;
    }

    setEmailForm(emailForm: ValidateEmailFormGroupType): void {
        this.emailForm.next(emailForm);
    }

    getEmailForm(): Observable<ValidateEmailFormGroupType | null> {
        return this.emailForm.asObservable();
    }

    markEmailAsTouched(): void {
        this.instant_emailForm?.markAllAsTouched();
    }

    setEmail(email: string): void {
        this.email = email;
    }

    getEmail(): string {
        return this.email;
    }
}
