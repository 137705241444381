<mat-selection-list [multiple]="false" #cards>
    <ng-container *ngIf="creditCards?.items?.length; else emptyCardList">
        <app-credit-cards-list-option
            *ngFor="let card of creditCards?.items"
            [loading]="loading"
            [creditCardItem]="card"
            class="w-100"
            (eventOnSelectCreditCard)="selectCreditCard($event)"
            [aboveThreshold]="aboveThreshold"
        ></app-credit-cards-list-option>
    </ng-container>
    <ng-template #emptyCardList>
        <app-empty-card-list *ngIf="displayEmptyList"></app-empty-card-list>
    </ng-template>
</mat-selection-list>
