import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Cart, PaymentMethods, PaymentMethodsType, StationFees } from '@cargos/sprintpay-models';
import { Subject, map, switchMap, take, takeUntil } from 'rxjs';
import { CartBillService } from 'src/app/services';
import { CustomerFeaturesService } from 'src/app/services/features/features.service';
import { PaymentMethodsSelected, TermsOfUseForm } from 'src/app/services/summary/models/types';
import { SummaryService } from 'src/app/services/summary/summary.service';
import { Balance, CartPayRequest } from 'src/app/utils/cart-types';

@Component({
    selector: 'app-payment-detail-v2',
    templateUrl: './payment-detail-v2.component.html',
})
export class PaymentDetailV2Component implements OnInit, OnDestroy {
    public paymentMethodBelowThreshold: PaymentMethodsType | null = null;
    public paymentMethodAboveThreshold: PaymentMethodsType | null = null;
    public isCartAboveThreshold: boolean = false;
    public subTotal: number = 0;
    public iscFee: number = 0;
    public nonIscFee: number = 0;
    public stationFees: StationFees[] = [];
    public customsFee: number = 0;
    public cartBill: Cart | null = null;
    public sprintPayCreditAmount: Balance = {};
    public isInvoiceCheckLimitValid: boolean = false;
    public invoicesInCart: boolean = false;
    public isTermsOfUseValid: boolean = false;
    public isSelectingPaymentMethod: boolean = false;
    public hasAGIPayments?: boolean;
    public nonReservationFee: number = 0;
    public handlingFeeTotal: number = 0;
    public creditCardFeeTotal: number = 0;
    public readonly paymentMethods = PaymentMethods;
    public isChangePaymentMethodButtonAvailableForGuest: boolean = false;
    public isCreditCardFeeAvailable: boolean = false;
    private unsubscribe$: Subject<void> = new Subject<void>();

    @Output() payPaypal: EventEmitter<CartPayRequest> = new EventEmitter();

    constructor(
        private matDialog: MatDialog,
        private changeDetectorRef: ChangeDetectorRef,
        private cartBillService: CartBillService,
        private customerFeaturesService: CustomerFeaturesService,
        private summaryService: SummaryService
    ) {}

    ngOnInit(): void {
        this.subscribeGetCurrentCart();
        this.subscribeToInvoiceCheckLimit();
        this.subscribeTermsOfUse();
        this.subcribePaymentMethodsSelectedBasedOnThreshold();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.matDialog.closeAll();
        this.changeDetectorRef.detach();
    }

    /**
     * @method subscribeGetCurrentCart()
     * @description Subscribes to the current cart and updates the cart bill and subtotal.
     *              This method listens for changes in the cart bill and updates the component state accordingly.
     *              It also checks for invoice limits and sets the invoice limit flag if necessary.
     */
    private subscribeGetCurrentCart(): void {
        this.cartBillService
            .getCartBill$()
            .pipe(
                switchMap((cartBill: Cart | null) => {
                    return this.cartBillService.getNonReservationFee$().pipe(
                        take(1),
                        map((nonReservationFeeTotal) => {
                            const isThereInvoicesInCart = this.cartBillService.isThereInvoicesInCart();
                            const isTherePaymentsRequestsNoInvoices =
                                this.cartBillService.isTherePaymentsRequestsNoInvoices();
                            const hasAGIPayments = this.cartBillService.hasAGIPayments();
                            return {
                                cartBill,
                                isThereInvoicesInCart,
                                isTherePaymentsRequestsNoInvoices,
                                hasAGIPayments,
                                nonReservationFeeTotal,
                            };
                        })
                    );
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe({
                next: ({ cartBill, isThereInvoicesInCart, hasAGIPayments, nonReservationFeeTotal }) => {
                    this.hasAGIPayments = hasAGIPayments;
                    this.invoicesInCart = isThereInvoicesInCart;
                    this.cartBill = cartBill;
                    this.subTotal = (cartBill?.subTotal || 0) + (cartBill?.threshold?.subTotal || 0);
                    this.iscFee = (cartBill?.iscFee || 0) + (cartBill?.threshold?.iscFee || 0);
                    this.nonIscFee = (cartBill?.nonIscFee || 0) + (cartBill?.threshold?.nonIscFee || 0);
                    this.customsFee = (cartBill?.customsFee || 0) + (cartBill?.threshold?.customsFee || 0);
                    this.stationFees = this.getStationFees(
                        cartBill?.stationFees || [],
                        cartBill?.threshold?.stationFees || []
                    );
                    this.nonReservationFee = nonReservationFeeTotal || 0;
                    this.handlingFeeTotal =
                        (cartBill?.handlingFeeTotal || 0) + (cartBill?.threshold?.handlingFeeTotal || 0);
                    this.creditCardFeeTotal =
                        (cartBill?.creditCardFeeTotal || 0) + (cartBill?.threshold?.creditCardFeeTotal || 0);
                },
            });
    }

    getStationFees(stationFees: StationFees[], thresholdStationFees: StationFees[]): StationFees[] {
        const feeMap = new Map<string, number>();

        const combinedArray = [...stationFees, ...thresholdStationFees];
        for (const fee of combinedArray) {
            feeMap.set(fee.label, (feeMap.get(fee.label) || 0) + fee.amount);
        }

        return Array.from(feeMap, ([label, amount]) => ({ label, amount }));
    }

    subscribeToInvoiceCheckLimit(): void {
        this.customerFeaturesService
            .isInvoiceCheckLimitValid$()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (isInvoiceCheckLimitValid) => {
                    this.isInvoiceCheckLimitValid = isInvoiceCheckLimitValid;
                },
            });
    }

    subscribeTermsOfUse(): void {
        this.summaryService
            .getTermsOfUseValid$()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((termsOfUse: TermsOfUseForm) => {
                this.isTermsOfUseValid = termsOfUse.isValidForm;
            });
    }

    subcribePaymentMethodsSelectedBasedOnThreshold(): void {
        this.summaryService
            .getPaymentMethodsSelectedBasedOnThreshold$()
            .pipe(
                map((paymentMethodsSelected: PaymentMethodsSelected) => {
                    return {
                        paymentMethodsSelected,
                        isCreditCardFeeAvailable:
                            this.isPaymentMethodSelected(paymentMethodsSelected, PaymentMethods.CREDIT_CARD) ||
                            this.isPaymentMethodSelected(paymentMethodsSelected, PaymentMethods.PAYPAL),
                    };
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe({
                next: ({ paymentMethodsSelected, isCreditCardFeeAvailable }) => {
                    this.isCreditCardFeeAvailable = isCreditCardFeeAvailable;
                    this.paymentMethodBelowThreshold = paymentMethodsSelected.paymentMethodSelected || null;
                    this.paymentMethodAboveThreshold =
                        paymentMethodsSelected.paymentMethodSelectedAboveThreshold || null;
                    this.isCartAboveThreshold = this.cartBillService.isCartAboveThreshold();
                },
            });
    }

    private isPaymentMethodSelected(
        paymentMethodsSelected: PaymentMethodsSelected,
        paymentMethodSelected: PaymentMethodsType
    ): boolean {
        return (
            paymentMethodsSelected.paymentMethodSelected === paymentMethodSelected ||
            paymentMethodsSelected.paymentMethodSelectedAboveThreshold === paymentMethodSelected
        );
    }
}
