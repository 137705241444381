import { Injectable } from '@angular/core';
import { BannerService } from '../standalone-components/banner/services/banner.service';
import { SummaryService } from './summary/summary.service';
import { CartBillService } from './utils/cart/cart-service';
import { PaymentFluxService } from './utils/payment-flux.service';
import { TokenService } from './utils/token.service';

/**
 * Service that is responsible for cleaning the user information that is stored in the cache
 */
@Injectable({
    providedIn: 'root',
})
export class ResetInformationService {
    constructor(
        private summaryService: SummaryService,
        private paymentFluxService: PaymentFluxService,
        private tokenService: TokenService,
        private bannerService: BannerService,
        private cartBillService: CartBillService
    ) {}

    resetUserInformation(): void {
        this.resetPaymentMethod();
        this.resetTermsOfUse();
        this.resetPaymentFlux();
        this.resetAuthTokens();
        this.resetNotifications();
        this.resetCart();
    }

    private resetPaymentMethod(): void {
        this.summaryService.setPaymentMethodSelected(null);
        this.summaryService.clearGuestData();
    }

    private resetTermsOfUse(): void {
        this.summaryService.setTermsOfUseValid(null);
    }

    private resetPaymentFlux(): void {
        this.paymentFluxService.removeCurrentPayment();
        this.paymentFluxService.removeDynamicValues();
        this.paymentFluxService.removeDynamicFields();
    }

    private resetAuthTokens(): void {
        this.tokenService.resetAuthTokens();
    }

    private resetNotifications(): void {
        this.bannerService.setNotifications([]);
    }

    private resetCart(): void {
        this.cartBillService.resetCart();
    }
}
