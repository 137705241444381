<!--START: RHENUS CUSTOMER REFERENCE -->
<div id="company_reference" class="mt-4">
    <div id="company_reference--container">
        <div class="mt-4 d-block">
            <h6 class="step_section mb-4">Rhenus Customer Reference</h6>
            <!--START: FORM -->
            <form class="row" [formGroup]="rhenusReferenceForm">
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <!--START: PAYMENT TYPE -->
                    <div class="form-group">
                        <mat-form-field class="d-block" appearance="outline">
                            <mat-label>Payment Amount</mat-label>
                            <mat-select
                                id="paymentAmount"
                                formControlName="paymentAmount"
                                [errorStateMatcher]="matcher"
                            >
                                <mat-option
                                    *ngFor="let paymentType of paymentMethodTypes; trackBy: trackBy"
                                    [value]="paymentType"
                                >
                                    {{paymentType}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="rhenusReferenceForm.controls['type'].hasError('required')">
                                <i class="bi bi-exclamation-triangle-fill me-2"></i>Required
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <!--END: PAYMENT TYPE -->
                    <!--START: LOCATION -->
                    <ng-container *ngIf="!locationAssigned">
                        <div class="form-group">
                            <mat-form-field class="d-block" appearance="outline">
                                <mat-label>Location</mat-label>
                                <mat-select
                                    id="locationId"
                                    [disabled]="onEdition"
                                    formControlName="locationId"
                                    [errorStateMatcher]="matcher"
                                >
                                    <mat-option
                                        (onSelectionChange)="locationSelected(item, $event)"
                                        *ngFor="let item of locations; trackBy: trackBy"
                                        [value]="item.id"
                                    >
                                        {{item.name}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="rhenusReferenceForm.controls['locationId'].hasError('required')">
                                    <i class="bi bi-exclamation-triangle-fill me-2"></i>Required
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </ng-container>
                    <!--END: LOCATION  -->
                    <!--START: TYPE -->
                    <div class="form-group">
                        <mat-form-field class="d-block" appearance="outline">
                            <mat-label>Type</mat-label>
                            <mat-select
                                id="type"
                                [disabled]="disabledInput"
                                formControlName="type"
                                [errorStateMatcher]="matcher"
                            >
                                <mat-option *ngFor="let item of typeOptions; trackBy: trackBy" [value]="item">
                                    {{item}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="rhenusReferenceForm.controls['type'].hasError('required')">
                                <i class="bi bi-exclamation-triangle-fill me-2"></i>Required
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <!--END: TYPE -->
                </div>
            </form>
            <!--END: FORM -->
        </div>
        <!--START: STEPPER NEXT BUTTON -->
        <div class="row">
            <div class="col-sm-12 col-md-3 offset-md-9">
                <div class="d-flex justify-content-end w-100">
                    <button
                        (click)="getRhenusValues()"
                        [ngClass]="rhenusReferenceForm.invalid ? 'btn__sprintpay--greenDisabled' : ''"
                        mat-button
                        color="primary"
                        type="button"
                        class="d-block border-0 rounded-0 text-uppercase btn__sprintpay btn__sprintpay--green cy__next--stepone"
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
        <!--END: STEPPER NEXT BUTTON -->
    </div>
</div>
<!--END: RHENUS CUSTOMER REFERENCE -->
