<mat-selection-list [multiple]="false" #eChecks>
    <ng-container *ngIf="!loading;">
        <ng-container *ngIf="echecks?.items?.length; else emptyCardList">
            <ng-container *ngFor="let echeck of echecks?.items; let index = index">
                <mat-list-option
                    class="echeck-list-item mb-4 p-0"
                    [value]="echeck.paymentToken?.token"
                    (click)="selectPaymentMethod(echeck.paymentToken?.token)"
                    [selected]="token === echeck.paymentToken?.token"
                    checkboxPosition="before"
                    color="primary"
                >
                    <div class="d-flex flex-row align-items-center pe-3">
                        <div class="d-flex flex-grow-1">
                            <app-logo-payment-method
                                [paymentMethodName]="paymentMethodsEnum.ECHECK"
                                width="2.3em"
                                class="me-3"
                            ></app-logo-payment-method>
                            @if (echeck.accountingDetails.accountLastFourDigits) {
                            <app-last-four-digits
                                [lastFourDigits]="echeck.accountingDetails.accountLastFourDigits"
                            ></app-last-four-digits>
                            }
                        </div>
                        @if(echeckDeletionAllowed) {
                        <mat-icon
                            *ngIf="!loading"
                            color="lighter"
                            class="md-icon-24 material-symbols-outlined"
                            (click)="removeEcheck(echeck)"
                            >delete_outline</mat-icon
                        >
                        <mat-icon
                            class="md-icon-24 material-symbols-outlined color-error-300 color-error-default-hover"
                            color="error"
                            *ngIf="loading"
                        >
                            <mat-spinner diameter="20" color="error"></mat-spinner>
                        </mat-icon>
                        }
                    </div>
                </mat-list-option>
            </ng-container>
        </ng-container>
        <ng-template #emptyCardList>
            <app-empty-echeck-list *ngIf="displayEmptyList"></app-empty-echeck-list>
        </ng-template>
    </ng-container>
</mat-selection-list>
