<div id="cart-items" class="invoice-list">
    <div class="pt-0">
        <mat-expansion-panel
            [expanded]="expandedPanel"
            [togglePosition]="'before'"
            class="mat-elevation-z0"
            [ngClass]="{'isInvoice': isThereInvoicesInCart}"
        >
            <mat-expansion-panel-header class="cart-items-header mb-4">
                <mat-panel-title class="d-flex justify-content-between flex-column flex-sm-row">
                    <div class="mat-subtitle-1">{{labelItemsInCart | titlecase}} in cart</div>
                    <div class="mat-caption summary">{{lengthCart}} {{labelItemsInCart}}</div>
                    <button
                        mat-button
                        type="submit"
                        class="mat-caption btn__sprintpay btn__sprintpay--link payment-detail--methodChange align-items-center"
                        (click)="deleteCart()"
                    >
                        <span *ngIf="!deletingCart"> Delete all </span>
                        <mat-icon class="ms-2 spinner-icon" *ngIf="deletingCart">
                            <mat-spinner color="error" diameter="20"></mat-spinner>
                        </mat-icon>
                    </button>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-list>
                <div
                    *ngFor="let payment of paymentRequest; let index = index; last as last; let first = first;"
                    class="h-auto p-0"
                    [ngClass]="{'mb-0': last, 'mb-4': !last}"
                >
                    @if (isThereInvoicesInCart) {
                    <app-cart-invoice [paymentRequest]="payment" class="p-0"></app-cart-invoice>
                    } @else {
                    <app-cart-awb [paymentRequest]="payment"></app-cart-awb>
                    }
                </div>
            </mat-list>
        </mat-expansion-panel>
    </div>
</div>
