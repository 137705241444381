export enum CartThresholdState {
    ABOVE_THRESHOLD = 'aboveThreshold',
    BELOW_THRESHOLD = 'belowThreshold',
}

/**
 * Only add fees that will be included in the total.
 */
export enum CartFees {
    HANDLING_FEE = 'handlingFee',
    CREDIT_CARD_FEE = 'creditCardFee',
}

export const ALL_FEES_LIST: readonly CartFees[] = Object.values(CartFees) as CartFees[];
