import { NgIf } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {
    CartDescriptionException,
    CartExceptionType,
    PaymentMethods,
    PaymentMethodsType,
} from '@cargos/sprintpay-models';
import { Subject, map, of, switchMap, takeUntil } from 'rxjs';
import { CartBillService } from 'src/app/services';
import { CustomerFeaturesService } from 'src/app/services/features/features.service';
import { SummaryService } from 'src/app/services/summary/summary.service';
import { PaymentMethodSelected } from 'src/app/utils/cart-types';
import { PayButtonService } from '../../payment-detail-v2/pay-button/services/pay-button.service';
import { PaymentMethodSelectedComponent } from '../payment-method-selected/payment-method-selected.component';

@Component({
    standalone: true,
    selector: 'app-authenticated-payment-method',
    imports: [NgIf, MatButtonModule, MatIconModule, PaymentMethodSelectedComponent],
    templateUrl: './authenticated-payment-method.component.html',
    styleUrls: ['./authenticated-payment-method.component.scss'],
})
export class AuthenticatedPaymentMethodComponent implements OnInit, OnDestroy {
    public paymentMethodSelected: PaymentMethodsType | null;
    public PaymentMethods = PaymentMethods;
    public showError: boolean = false;
    public msgError: string;
    public isSelectingPaymentMethod: boolean = false;
    private unsubscribe$: Subject<void> = new Subject<void>();

    /**
     * This variable do not define is the cart is above the threshold, is a configuration to get the payment method selected based on section
     */
    @Input() aboveThreshold: boolean = false;

    constructor(
        private payButtonService: PayButtonService,
        private summaryService: SummaryService,
        private customerFeaturesService: CustomerFeaturesService,
        private cartBillService: CartBillService
    ) {
        this.msgError = CartDescriptionException[CartExceptionType.PAYMENT_METHOD_INVALID];
    }

    ngOnInit(): void {
        this.subscribeGetDefaultPaymentMethodAndCart();
        this.submittedPaymentAndPaymentValid();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    submittedPaymentAndPaymentValid(): void {
        this.payButtonService
            .isPayButtonSubmitted()
            .pipe(
                switchMap((submitted: boolean) => {
                    if (!submitted) {
                        return of(false);
                    }

                    return this.customerFeaturesService
                        .isPaymentMethodSelectedValid$(this.aboveThreshold)
                        .pipe(map((valid: boolean) => !valid));
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe({
                next: (invalidPaymentMethod: boolean) => {
                    this.showError = invalidPaymentMethod;
                },
            });
    }

    /**
     * @method subscribeGetDefaultPaymentMethodAndCart()
     * @description Preselect payment method
     */
    private subscribeGetDefaultPaymentMethodAndCart(): void {
        this.isSelectingPaymentMethod = true;

        this.summaryService
            .getPaymentMethodSelected$(this.aboveThreshold)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (selectedPayment: PaymentMethodSelected | null) => {
                    this.isSelectingPaymentMethod = false;

                    // this.paymentMethodSelected = selectedPayment?.method || null;

                    if (
                        selectedPayment &&
                        this.customerFeaturesService.isPaymentMethodsSelectedOnCartAboveValid(selectedPayment) &&
                        this.cartBillService.isCartAboveThreshold()
                    ) {
                        this.paymentMethodSelected = selectedPayment?.method;
                        return;
                    } else if (!this.cartBillService.isCartAboveThreshold()) {
                        this.paymentMethodSelected = selectedPayment?.method || null;
                    } else {
                        this.paymentMethodSelected = null;
                    }
                },
                error: () => {
                    this.isSelectingPaymentMethod = false;
                },
            });
    }
}
