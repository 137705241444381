import { Component, Input, OnInit } from '@angular/core';
import { PaymentCart } from '@cargos/sprintpay-models';
@Component({
    selector: 'app-cart-invoice',
    templateUrl: './cart-invoice.component.html',
    styleUrls: ['./cart-invoice.component.scss'],
})
export class CartInvoiceComponent implements OnInit {
    public paymentType: string = '';
    public invoiceNumber: number | undefined;

    @Input() paymentRequest: PaymentCart;

    ngOnInit(): void {
        this.invoiceNumber = this.paymentRequest.originalInvoice;
        this.paymentType = this.getPaymentTypeForAwb();
    }

    getFacilityName(): string | undefined {
        return this.invoiceNumber ? undefined : this.paymentRequest.facility?.name;
    }

    getPaymentTypeForAwb(): string {
        return this.paymentRequest.paymentType || '';
    }
}
