<div class="cart-buttons">
    <ng-container *ngIf="isEditable">
        <button
            class="mat-caption btn__sprintpay--link align-items-center d-flex me-3"
            (click)="editCart()"
            type="button"
            mat-button
        >
            <span *ngIf="!deletingCart">Edit</span>
            <mat-icon class="ms-2 spinner-icon" color="error" *ngIf="deletingCart">
                <mat-spinner diameter="20" color="error"></mat-spinner>
            </mat-icon>
        </button>
        <mat-divider [vertical]="true" class="me-3"></mat-divider>
    </ng-container>
    <button
        class="mat-caption btn__sprintpay--link align-items-center d-flex"
        mat-button
        type="button"
        (click)="deleteCart()"
    >
        <span *ngIf="!deletingCart">Delete</span>
        <mat-icon class="ms-2 spinner-icon" color="error" *ngIf="deletingCart">
            <mat-spinner diameter="20" color="error"></mat-spinner>
        </mat-icon>
    </button>
</div>
