<section id="paymentMethodsList">
    <mat-accordion>
        <mat-expansion-panel [expanded]="expandedPanel" hideToggle disabled="true" class="mat-elevation-z0">
            <mat-expansion-panel-header class="p-0">
                <ng-container
                    *ngIf="!methodsForThreshold.length && !paymentMethodsList.length && !isSelectingPaymentMethod; else noPaymentMethods"
                >
                    <div class="d-flex error-message mb-2">
                        There was a technical issue on our end loading your payment methods. Please try again by logging
                        out, then logging back in. If the issue persists please contact support&#64;cargosprint.com
                    </div>
                </ng-container>
                <ng-template #noPaymentMethods>
                    <div class="d-flex flex-column flex-grow-1">
                        <ng-content></ng-content>
                        @if (isAuthenticated) {
                        <div class="mat-subtitle-1 methods-title mb-4">Payment method</div>
                        <div class="d-flex flex-xs-column flex-row flex-grow-1 align-items-center mb-32">
                            <ng-container *ngIf="!isSelectingPaymentMethod; else loader">
                                <div class="d-flex flex-grow-1 w-100">
                                    <app-authenticated-payment-method [aboveThreshold]="aboveThreshold">
                                    </app-authenticated-payment-method>
                                </div>
                                <!-- START: CHANGE/SELECT PAYMENT METHOD BUTTON -->
                                <button
                                    mat-button
                                    type="submit"
                                    class="mat-caption btn__sprintpay btn__sprintpay--link payment-detail--methodChange align-items-center d-flex"
                                    (click)="togglePanel()"
                                    *ngIf="!expandedPanel"
                                >
                                    {{ paymentMethodSelected?.method ? 'Change' : 'Select payment method'}}
                                </button>
                                <!-- END: CHANGE/SELECT PAYMENT METHOD BUTTON -->
                            </ng-container>

                            <!--START: LOADER -->
                            <ng-template #loader>
                                <div class="d-flex justify-content-center flex-grow-1">
                                    <mat-spinner [diameter]="30"></mat-spinner>
                                </div>
                            </ng-template>
                            <!--END: LOADER -->
                        </div>
                        }
                    </div>
                </ng-template>
            </mat-expansion-panel-header>
            <div class="d-flex justify-content-between mb-4">
                <div class="d-flex mat-subtitle-1 methods-title mb-0">Select a payment method</div>
                <ng-container *ngIf="isAuthenticated">
                    <button
                        mat-button
                        type="submit"
                        class="mat-caption btn__sprintpay btn__sprintpay--link payment-detail--methodChange align-items-center d-flex"
                        (click)="togglePanel()"
                    >
                        <mat-icon matIconPrefix class="close-icon">close</mat-icon>
                        Close
                    </button>
                </ng-container>
            </div>

            <ng-container *ngIf="(aboveThreshold && !methodsForThreshold.length || !aboveThreshold)">
                <ng-container *ngFor="let method of paymentMethodsList">
                    @if (method.name === PaymentMethods.CREDIT_CARD) {
                    <div class="methods-container">
                        <ng-container *ngIf="!isAuthenticated; else creditCardAuth">
                            <app-guest-credit-card
                                (eventOnSelectCreditCard)="selectPaymentMethod(PaymentMethods.CREDIT_CARD, $event)"
                            ></app-guest-credit-card>
                        </ng-container>
                        <ng-template #creditCardAuth>
                            <app-credit-cards-list
                                [aboveThreshold]="aboveThreshold"
                                (eventOnSelectCreditCard)="selectPaymentMethod(PaymentMethods.CREDIT_CARD, $event)"
                            ></app-credit-cards-list>
                            <div class="d-flex">
                                <button
                                    mat-button
                                    type="submit"
                                    class="d-flex align-items-center mat-body-1 btn__sprintpay btn__sprintpay--link btn-green mb-0"
                                    (click)="redirectToCreditCard()"
                                >
                                    <mat-icon matIconPrefix>add</mat-icon>
                                    ADD NEW CREDIT CARD
                                </button>
                            </div>
                        </ng-template>
                    </div>
                    } @if (method.name === PaymentMethods.ECHECK) {
                    <div class="methods-container">
                        <mat-divider class="mx-0 my-4" [inset]="true"></mat-divider>
                        <app-echecks-list
                            [aboveThreshold]="aboveThreshold"
                            (eventOnSelectEcheck)="selectPaymentMethod(PaymentMethods.ECHECK, $event)"
                        ></app-echecks-list>
                        <div class="d-flex">
                            <button
                                mat-button
                                type="submit"
                                class="d-flex align-items-center mat-body-1 btn__sprintpay btn__sprintpay--link btn-green mb-0"
                                (click)="redirectToEcheck()"
                            >
                                <mat-icon matIconPrefix>add</mat-icon>
                                ADD NEW ECHECK
                            </button>
                        </div>
                    </div>
                    } @if (method.name === PaymentMethods.CARGO_CREDIT && !aboveThreshold && !isThereInvoicesInCart){
                    <div class="methods-container">
                        <mat-divider class="mx-0 my-4" [inset]="true"></mat-divider>
                        <app-cargo-credit
                            [aboveThreshold]="aboveThreshold"
                            (eventOnSelectCargoCredit)="selectPaymentMethod(PaymentMethods.CARGO_CREDIT)"
                        ></app-cargo-credit>
                    </div>
                    } @if (method.name === PaymentMethods.PAYPAL && !aboveThreshold && !isCartAboveThreshold) {
                    <div class="methods-container">
                        <mat-divider class="mx-0 my-4" [inset]="true"></mat-divider>
                        <app-paypal
                            [aboveThreshold]="aboveThreshold"
                            (eventOnSelectPaypal)="selectPaymentMethod(PaymentMethods.PAYPAL)"
                        ></app-paypal>
                    </div>
                    }
                </ng-container>
                <mat-divider class="mx-0 mt-4" [inset]="true"></mat-divider>
            </ng-container>
            <ng-container #paymentsAbove *ngIf="aboveThreshold && methodsForThreshold.length">
                <div class="d-flex flex-column">
                    <ng-container *ngFor="let method of methodsForThreshold">
                        @if (method.name === PaymentMethods.CREDIT_CARD) {
                        <div class="methods-container">
                            <app-credit-cards-list
                                [aboveThreshold]="aboveThreshold"
                                (eventOnSelectCreditCard)="selectPaymentMethod(PaymentMethods.CREDIT_CARD, $event)"
                            ></app-credit-cards-list>
                            <div class="d-flex">
                                <button
                                    mat-button
                                    type="submit"
                                    class="d-flex align-items-center mat-body-1 btn__sprintpay btn__sprintpay--link btn-green mb-0"
                                    (click)="redirectToCreditCard()"
                                >
                                    <mat-icon matIconPrefix>add</mat-icon>
                                    ADD NEW CREDIT CARD
                                </button>
                            </div>
                            <mat-divider class="m-4" [inset]="true"></mat-divider>
                        </div>
                        } @if (method.name === PaymentMethods.ECHECK) {
                        <div class="methods-container">
                            <app-echecks-list
                                [aboveThreshold]="aboveThreshold"
                                (eventOnSelectEcheck)="selectPaymentMethod(PaymentMethods.ECHECK, $event)"
                            ></app-echecks-list>
                            <div class="d-flex mb-4">
                                <button
                                    mat-button
                                    type="submit"
                                    class="d-flex align-items-center mat-body-1 btn__sprintpay btn__sprintpay--link btn-green mb-0"
                                    (click)="redirectToEcheck()"
                                >
                                    <mat-icon matIconPrefix>add</mat-icon>
                                    ADD NEW ECHECK
                                </button>
                            </div>
                        </div>
                        }
                    </ng-container>
                </div>
            </ng-container>
        </mat-expansion-panel>
    </mat-accordion>
</section>
