<ng-container *ngIf="paymentMethodName">
    <div [ngStyle]="{'width': width, 'height': height}">
        <app-image-loader
            src="assets/images/payment-methods/{{paymentMethodName}}.svg"
            [alt]="alt ? alt : paymentMethodName + '-logo'"
            [title]="title ? title : paymentMethodName + '-logo'"
            [width]="width"
            [height]="height"
        ></app-image-loader>
    </div>
</ng-container>
